import AuthArea from './AuthArea';

const Auth = () => {
  return (
    <>
      <AuthArea />
    </>
  );
};

export default Auth;
