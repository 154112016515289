import React from 'react';
import styled from 'styled-components';
import { CircleImageWithName } from '../../../../../components/common/icon/CircleImageWithName';
import { Player } from '../../../../userdata/history/type';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

const LineupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  flex-grow: 1;
  max-width: calc(20%); /* 기본적으로 5개의 요소가 들어가도록 설정 */
`;

const MiddleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap; /* 줄바꿈을 허용 */
  padding: 20px 0;
  justify-content: space-between;
  align-items: center;
`;

interface TeamSectionProps {
  team: 'blue' | 'red';
  players: Player[];
  editable?: boolean;
  vm: any;
}

export const TeamPlayerSection: React.FC<TeamSectionProps> = observer(
  ({ team, players, vm, editable = true }) => {
    const handlePlayerClick = (index: number) => {
      if (editable) {
        vm.setHighlightedPlayer({ team, index });
      }
    };
    const positions = ['top', 'jungle', 'mid', 'adc', 'support'];

    return (
      <MiddleContainer>
        {players.map((player, index) => (
          <LineupContainer key={index}>
            <CircleImageWithName
              highlighted={
                editable &&
                vm.highlightedPlayer.team === team &&
                vm.highlightedPlayer.index === index
              }
              onClick={() => handlePlayerClick(index)}
              player={{
                ...player,
                is_db: true,
                is_syncronized: true,
                isbase: true,
              }}
              name={player.name ? player.name : 'Player ' + (index + 1)}
              team={team}
              radius={120} // 반응형이 적용되도록 수정해야 할 부분
              position={positions[index]}
            />
          </LineupContainer>
        ))}
      </MiddleContainer>
    );
  }
);

export default TeamPlayerSection;
