import React from 'react';
import {
  useReactTable,
  ColumnDef,
  getCoreRowModel,
  flexRender,
  Table,
} from '@tanstack/react-table';
import styled from 'styled-components';
import { Champ, Metadata, TeamMetadata } from '../../type';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

const InfoIcon = styled.img`
  border-radius: 2.5%;
  width: 60px;
  height: 60px;
  border: 1px solid #f6f6f6;
`;

const DetailTableContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse; /* 테두리 중복 제거 */
  font-size: 16px;
  text-align: left;
  color: #fff;
  table-layout: fixed;

  th,
  td {
    padding: 8px 12px;
    border: 1px solid #242424; /* 테두리 색 */
    width: 16%;
  }

  th:nth-child(1) {
    width: 50px;
  }

  th {
    font-weight: bold;
  }
`;

const CellStyle = styled.div<{
  is_blue: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  font-family: 'Pretendard';
  gap: 10px;

  background: ${({ is_blue }) => (is_blue ? '#9898FF60' : '#FF797940')};
  color: white;
`;

const BlueBar = styled.div<{ $width: number }>`
  width: ${({ $width }) => $width}%;
  height: 20px;
  background-color: #9898ff;

  display: flex;

  font-size: 12px;
  font-weight: 600;
  color: black;

  text-align: left;
  padding: 2px;
  box-sizing: border-box;
  align-items: center;
`;

const RedBar = styled.div<{ $width: number }>`
  width: ${({ $width }) => $width}%;
  height: 20px;
  background-color: #ff7979;

  display: flex;
  font-weight: 600;
  font-size: 12px;
  color: black;

  text-align: right;
  align-items: center;
  padding: 2px;

  box-sizing: border-box;
  justify-content: flex-end;
`;

const LittleValue = styled.div`
  font-size: 0.5em;
`;

const Cell = ({
  blueChamp,
  redChamp,
  value,
}: {
  blueChamp: string;
  redChamp: string;
  value: number;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={blueChamp}
        style={{ width: '20px', height: '20px' }}
        alt="blueChamp"
      />
      <span style={{ width: 3, boxSizing: 'border-box' }} />

      <BlueBar $width={value}>{value.toFixed(1)}</BlueBar>
      <RedBar $width={100 - value}>{(100 - value).toFixed(1)}</RedBar>

      <span style={{ width: 3, boxSizing: 'border-box' }} />
      <img
        src={redChamp}
        style={{ width: '20px', height: '20px' }}
        alt="redChamp"
      />
    </div>
  );
};

const columns: ColumnDef<Metadata>[] = [
  {
    accessorKey: 'position',
    header: '포지션',
  },
  {
    accessorKey: 'exp',
    header: '경험치',
  },
  {
    accessorKey: 'cs',
    header: 'CS',
  },
  {
    accessorKey: 'dpm',
    header: '분당 딜량',
  },
  {
    accessorKey: 'gold',
    header: '획득 골드',
  },
  {
    accessorKey: 'kda',
    header: 'KDA',
  },
  {
    accessorKey: 'vision',
    header: '시야 점수',
  },
];

const DetailTable = observer(
  ({ champions, data }: { champions: any; data: any }) => {
    const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
    });
    if (!data) {
      return null;
    }

    return (
      <DetailTableContainer>
        <StyledTable>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {cell.column.id === 'position' ? (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    ) : cell.getValue() ? (
                      <Cell
                        value={data[index][cell.column.id]}
                        blueChamp={champions[index].championImage}
                        redChamp={champions[index + 5].championImage}
                      />
                    ) : (
                      <Cell
                        value={0}
                        blueChamp={champions[index].championImage}
                        redChamp={champions[index + 5].championImage}
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </DetailTableContainer>
    );
  }
);

const ChartContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 20px;

  gap: 30px;
`;

const BarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 25%;
`;

const Bar = styled.div`
  width: 40px;
  height: 200px;
  background-color: #ccc;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
`;

const TeamBarPortion = styled.div<{ height: number; team: 'blue' | 'red' }>`
  width: 100%;
  height: ${({ height }) => height}%;
  background-color: ${({ team }) => (team === 'blue' ? '#9898FF' : '#FF7979')};

  display: flex;
  font-size: 12px;
  font-weight: 600;

  align-items: flex-end;
`;

const BarLabel = styled.div`
  margin-top: 5px;
  font-size: 14px;
  color: #fff;
  text-align: center;
`;

type ObjectiveKey = 'herald' | 'dragon' | 'baron' | 'soul' | 'elder';

const objectives: ObjectiveKey[] = [
  'herald',
  'dragon',
  'baron',
  'soul',
  'elder',
];

const toKorKey = (key: ObjectiveKey) => {
  switch (key) {
    case 'herald':
      return '전령';
    case 'dragon':
      return '용';
    case 'baron':
      return '바론';
    case 'soul':
      return '영혼';
    case 'elder':
      return '장로 용';
  }
};

const TeamMetaChart = observer(({ data }: { data?: TeamMetadata | null }) => {
  if (!data) {
    return null; // 데이터가 부족한 경우 처리
  }

  return (
    <ChartContainer>
      {objectives.map((obj) => {
        const blueValue = data[obj][0];
        const redValue = data[obj][1];

        const bluePercentage = blueValue;
        const redPercentage = redValue;

        return (
          <BarContainer key={obj}>
            <Bar>
              <TeamBarPortion height={bluePercentage} team="blue">
                {bluePercentage.toFixed(1)}
              </TeamBarPortion>
              <TeamBarPortion height={redPercentage} team="red">
                {redPercentage.toFixed(1)}
              </TeamBarPortion>
            </Bar>
            <BarLabel>{toKorKey(obj)}</BarLabel>
          </BarContainer>
        );
      })}
    </ChartContainer>
  );
});

export { DetailTable, TeamMetaChart };
