import React, { useEffect, useState } from 'react';
import useChampions from '../../hooks/useChampions';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import InsigniaBG from '../../assets/InsigniaBG.png';

const Container = styled.div`
  overflow-x: hidden;

  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 800px;

  @media (max-width: 1024px) {
    min-width: 480px;
  }

  @media (max-width: 480px) {
    min-width: 100px;
  }
`;

const ContentsBox = styled.div<{ $height100: boolean }>`
  width: 800px;
  height: ${(props) => (props.$height100 ? '100vh' : 'auto')};

  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-self: center;
  justify-self: center;

  box-sizing: border-box;

  @media (max-width: 1024px) {
    width: 650px;
  }

  @media (max-width: 480px) {
    width: 300px;
    padding: 20px;
  }
`;

const SectionContainer = styled.div<{ theme: string; height: number | string }>`
  width: 100%;
  position: relative;

  overflow-y: hidden;
  overflow-x: hidden;

  padding: 100px 0px;

  height: ${(props) =>
    typeof props.height === 'string' ? props.height : props.height + 'px'};
  display: flex;
  flex-direction: column;

  min-width: 800px;

  font-family: 'Pretendard';
  background-color: ${(props) =>
    props.theme === 'dark' ? '#000000' : '#ffffff'};

  @media (max-width: 1024px) {
    min-width: 480px;
    padding: 50px 0px;
  }

  @media (max-width: 480px) {
    min-width: 100px;
    padding: 20px 0px;
  }
`;

const SubTitle = styled.div`
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;

  color: #000000;

  white-space: pre-wrap;

  @media (max-width: 1024px) {
    font-size: 48px;
  }

  @media (max-width: 480px) {
    font-size: 28px;
  }
`;

const ProductName = styled.span`
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 128px;

  color: #ffffff;

  background-color: #000000;

  align-self: center;

  margin: 50px;

  @media (max-width: 1024px) {
    font-size: 88px;
  }

  @media (max-width: 480px) {
    font-size: 60px;
  }
`;

const ActionButtonStyle = styled.a`
  width: 500px;
  height: 130px;
  background: #192244;
  border-radius: 30px;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  align-self: center;
  margin-top: 70px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  color: #ffffff;

  z-index: 1;

  @media (max-width: 1024px) {
    font-size: 32px;
    width: 400px;
    margin-top: 40px;
  }

  @media (max-width: 480px) {
    width: 250px;
    height: 60px;
    font-size: 20px;
    border-radius: 12px;
    margin-top: 20px;
  }

  cursor: pointer;
`;

const ButtonContextStyle = styled.div`
  padding: 10px 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 480px) {
    padding: 0px;
  }
`;

const FirstSection = () => {
  return (
    <SectionContainer theme="light" height={'auto'}>
      <ContentsBox $height100={true}>
        <SubTitle>
          {'League of Legends®,\n소환사들을 위한\n최적의 Draft 추천 시스템.'}
        </SubTitle>
        <ProductName>{'GLPT™'}</ProductName>

        <ActionButtonStyle href="/home">
          <ButtonContextStyle>
            <div>GLPT 사용해 보기</div>
          </ButtonContextStyle>
        </ActionButtonStyle>
      </ContentsBox>
    </SectionContainer>
  );
};

const InsigniaBGImage = styled.img`
  position: absolute;
  align-self: center;
  width: 1352px;
  height: 1024px;
  object-fit: cover;
`;

const GridContainer = styled.div`
  width: 1352px;
  height: 1024px;
  padding: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

const ImageGrid = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 5% 1.5%;
  justify-content: center;
  align-content: center;

  overflow: hidden;
`;

const ChampIcon = styled(motion.div)`
  position: relative;
  width: 140px;
  height: 140px;
  overflow: hidden;

  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.5),
    inset 0px -4px 8px rgba(0, 0, 0, 0.25),
    inset 0px 4px 8px rgba(255, 255, 255, 0.25);
  border-radius: 12px;

  z-index: 6;
`;

const ChampIconImage = styled(motion.img)`
  position: absolute;

  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;

  z-index: 5;
`;

const PhraseStyle = styled.div`
  position: absolute;

  justify-self: center;
  align-self: center;

  top: calc(50% - 96px);

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 96px;

  color: #ffffff;

  white-space: pre-wrap;
  z-index: 10;

  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 1));

  @media (max-width: 1024px) {
    font-size: 64px;
    top: calc(50% - 64px);
  }

  @media (max-width: 480px) {
    font-size: 32px;
    top: calc(50% - 32px);
  }
`;

const SecondSection = () => {
  const [selectedChamps1, setSelectedChamps1] = useState<string[]>([]);
  const [selectedChamps2, setSelectedChamps2] = useState<string[]>([]);
  const [selectedChamps3, setSelectedChamps3] = useState<string[]>([]);
  const [selectedChamps4, setSelectedChamps4] = useState<string[]>([]);
  const [selectedChamps5, setSelectedChamps5] = useState<string[]>([]);

  const [phraseIndex, setPhraseIndex] = useState(0);

  const phrasesOrder = [
    '각 턴에 맞는\n최적의 챔피언 선택.',
    '3억 개 이상의\n게임 데이터 활용.',
    '심층적 모델로\n정확도 및 캐피시티 향상',
  ];

  const selectRandomChamps = (champs: string[], count: number): string[] => {
    const shuffled = champs.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const champions = useChampions();

  const delayList = [0, 3, 3, 6, 0, 6, 3, 0, 6, 6, 3, 0, 6, 3, 6, 6, 0, 3, 3];

  useEffect(() => {
    const randomChamps = async () => {
      const championsList = champions.map((champion) => {
        return champion.image;
      });

      const randomChamps1 = selectRandomChamps(championsList, 10);
      const randomChamps2 = selectRandomChamps(championsList, 10);
      const randomChamps3 = selectRandomChamps(championsList, 10);
      const randomChamps4 = selectRandomChamps(championsList, 10);
      const randomChamps5 = selectRandomChamps(championsList, 10);

      setSelectedChamps1(randomChamps1.concat(randomChamps1.slice(0, 9)));
      setSelectedChamps2(randomChamps2.concat(randomChamps2.slice(0, 9)));
      setSelectedChamps3(randomChamps3.concat(randomChamps3.slice(0, 9)));
      setSelectedChamps4(randomChamps4.concat(randomChamps4.slice(0, 9)));
      setSelectedChamps5(randomChamps5.concat(randomChamps5.slice(0, 9)));
    };

    randomChamps();
  }, [champions]);

  useEffect(() => {
    const interval = setInterval(() => {
      setPhraseIndex((phraseIndex + 1) % phrasesOrder.length);
    }, 5000);

    return () => clearInterval(interval);
  });

  return (
    <SectionContainer theme="light" height={1024}>
      <PhraseStyle>{phrasesOrder[phraseIndex]}</PhraseStyle>
      <InsigniaBGImage src={InsigniaBG} />
      <GridContainer>
        <ImageGrid>
          {selectedChamps1.map((champ, index) => (
            <ChampIcon
              key={index}
              initial={{ x: '-575%' }}
              animate={{ x: ['-575%', '570%'] }}
              transition={{
                repeat: Infinity,
                repeatType: 'loop',
                duration: 8,
                ease: 'linear',
              }}
            >
              <ChampIconImage
                src={champ}
                alt={`image-${index}`}
                initial={{ filter: 'none' }}
                animate={{
                  filter: [
                    'none',
                    'grayscale(100%)',
                    'grayscale(100%)',
                    'none',
                    'none',
                  ],
                }}
                transition={{
                  repeat: Infinity,
                  repeatType: 'loop',
                  duration: 6,
                  delay: delayList[index],
                  times: [0, 0.01, 0.5, 0.51, 1],
                  ease: 'linear',
                }}
              />
            </ChampIcon>
          ))}
          {selectedChamps2.map((champ, index) => (
            <ChampIcon
              key={index}
              initial={{ x: '570%' }}
              animate={{ x: ['570%', '-575%'] }}
              transition={{
                repeat: Infinity,
                repeatType: 'loop',
                duration: 8,
                ease: 'linear',
              }}
            >
              <ChampIconImage
                src={champ}
                alt={`image-${index}`}
                initial={{ filter: 'none' }}
                animate={{
                  filter: [
                    'none',
                    'grayscale(100%)',
                    'grayscale(100%)',
                    'none',
                    'none',
                  ],
                }}
                transition={{
                  repeat: Infinity,
                  repeatType: 'loop',
                  duration: 6,
                  delay: delayList[index],
                  times: [0, 0.01, 0.5, 0.51, 1],
                  ease: 'linear',
                }}
              />
            </ChampIcon>
          ))}
          {selectedChamps3.map((champ, index) => (
            <ChampIcon
              key={index}
              initial={{ x: '-575%' }}
              animate={{ x: ['-575%', '570%'] }}
              transition={{
                repeat: Infinity,
                repeatType: 'loop',
                duration: 8,
                ease: 'linear',
              }}
            >
              <ChampIconImage
                src={champ}
                alt={`image-${index}`}
                initial={{ filter: 'none' }}
                animate={{
                  filter: [
                    'none',
                    'grayscale(100%)',
                    'grayscale(100%)',
                    'none',
                    'none',
                  ],
                }}
                transition={{
                  repeat: Infinity,
                  repeatType: 'loop',
                  duration: 6,
                  delay: delayList[index],
                  times: [0, 0.01, 0.5, 0.51, 1],
                  ease: 'linear',
                }}
              />
            </ChampIcon>
          ))}
          {selectedChamps4.map((champ, index) => (
            <ChampIcon
              key={index}
              initial={{ x: '570%' }}
              animate={{ x: ['570%', '-575%'] }}
              transition={{
                repeat: Infinity,
                repeatType: 'loop',
                duration: 8,
                ease: 'linear',
              }}
            >
              <ChampIconImage
                src={champ}
                alt={`image-${index}`}
                initial={{ filter: 'none' }}
                animate={{
                  filter: [
                    'none',
                    'grayscale(100%)',
                    'grayscale(100%)',
                    'none',
                    'none',
                  ],
                }}
                transition={{
                  repeat: Infinity,
                  repeatType: 'loop',
                  duration: 6,
                  delay: delayList[index],
                  times: [0, 0.01, 0.5, 0.51, 1],
                  ease: 'linear',
                }}
              />
            </ChampIcon>
          ))}
          {selectedChamps5.map((champ, index) => (
            <ChampIcon
              key={index}
              initial={{ x: '-575%' }}
              animate={{ x: ['-575%', '570%'] }}
              transition={{
                repeat: Infinity,
                repeatType: 'loop',
                duration: 8,
                ease: 'linear',
              }}
            >
              <ChampIconImage
                src={champ}
                alt={`image-${index}`}
                initial={{ filter: 'none' }}
                animate={{
                  filter: [
                    'none',
                    'grayscale(100%)',
                    'grayscale(100%)',
                    'none',
                    'none',
                  ],
                }}
                transition={{
                  repeat: Infinity,
                  repeatType: 'loop',
                  duration: 6,
                  delay: delayList[index],
                  times: [0, 0.01, 0.5, 0.51, 1],
                  ease: 'linear',
                }}
              />
            </ChampIcon>
          ))}
        </ImageGrid>
      </GridContainer>
    </SectionContainer>
  );
};

const Title = styled.div`
  align-self: center;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 250px;

  color: #000000;

  margin: 100px 0px;

  @media (max-width: 1024px) {
    font-size: 18vw;
  }

  @media (max-width: 480px) {
    font-size: 20vw;
  }
`;

const ThirdSection = () => {
  return (
    <SectionContainer theme="light" height={'auto'}>
      <ContentsBox $height100={false}>
        <SubTitle>{'전략적인 밴픽이 필요한\n모든 프로 구단에게.'}</SubTitle>
      </ContentsBox>
      <Title>{'GLPT™'}</Title>
    </SectionContainer>
  );
};

const LandingView = () => {
  return (
    <div>
      <Container>
        <FirstSection />
        <SecondSection />
        <ThirdSection />
      </Container>
    </div>
  );
};

export default LandingView;
