import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import styled, { keyframes, css } from 'styled-components';
import { Champ } from '../../type';

const glowing = keyframes`
  0% {
    box-shadow: 0 0 10px 0px rgba(0, 222, 155, 1);
  }
  50% {
    box-shadow: 0 0 10px 8px rgba(0, 222, 155, 1);
  }
  100% {
    box-shadow: 0 0 10px 0px rgba(0, 222, 155, 1);
  }
`;

const SectionContainer = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
  align-items: left;
  width: ${({ width }) => width};
`;

const BigBanItemImage = styled.div<{ team: string; isChampion: boolean }>`
  width: 100%;
  ${({ isChampion }) =>
    !isChampion ? 'padding-bottom: 100%;' : 'padding-bottom: 0%;'}
  border: 1px dashed #ccc;
  box-sizing: border-box;
  background: ${({ team }) =>
    team === 'red' ? 'rgba(255, 121, 121, 1)' : 'rgba(121, 223, 255, 1)'};
`;

const BigBanItemContainer = styled.div<{
  $isHighlighted: boolean;
  $isPickEnded?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  max-width: calc(20%);

  ${({ $isHighlighted }) =>
    $isHighlighted
      ? css`
          animation: ${glowing} 1s infinite;
        `
      : 'none;'}

  cursor: ${(props) =>
    props.$isPickEnded !== undefined && props.$isPickEnded === true
      ? 'grab'
      : 'pointer'};
`;

const SmallBanItemContainer = styled.div<{ $isHighlighted: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  max-width: calc(20%);

  ${({ $isHighlighted }) =>
    $isHighlighted
      ? css`
          animation: ${glowing} 1s infinite;
        `
      : 'none'}
`;
const SamllBanItemImage = styled.div<{ team: string; isChampion: boolean }>`
  width: 100%;
  ${({ isChampion }) =>
    !isChampion ? 'padding-bottom: 100%;' : 'padding-bottom: 0%;'}
  border: 1px dashed #ccc;
  box-sizing: border-box;
  background-color: ${({ team }) =>
    team === 'red' ? 'rgba(255, 121, 121, 1)' : 'rgba(121, 223, 255, 1)'};
  background-image: url('/crossLine.png');
  background-size: cover;
  background-position: center; /* 배경 이미지를 가운데로 설정 */
  background-repeat: no-repeat; /* 배경 이미지 반복을 제거 */
`;
interface Props {
  width?: string;
  vm: any;
}

const ItemContainer = styled.div`
  display: flex;
  flex: wrap;
`;
const ItemContainer2 = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const BigBanItem = observer(
  ({
    team,
    index,
    isHighlighted,
    selectedChamp,
    confirmedChamp,
    furtherChamp,
    onClick,
    handleDragStart,
    handleDragOver,
    handleDrop,
    champ,
  }: {
    team: string;
    index: number;
    isHighlighted?: boolean;
    selectedChamp?: string | null;
    confirmedChamp?: string | null;
    furtherChamp?: string | null;
    onClick?: (index: number) => void;
    handleDragStart: (
      event: React.DragEvent<HTMLDivElement>,
      champ: Champ | null,
      sourceIndex: number
    ) => void;
    handleDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
    handleDrop: (event: React.DragEvent<HTMLDivElement>, index: number) => void;
    champ: Champ | null;
  }) => {
    if (isHighlighted) {
      return (
        <BigBanItemContainer
          $isHighlighted={isHighlighted ? isHighlighted : false}
          onClick={onClick && (() => onClick(index))}
        >
          <BigBanItemImage
            team={team}
            isChampion={selectedChamp ? true : false}
          >
            {selectedChamp ? (
              <img src={selectedChamp} alt={selectedChamp} width={'100%'} />
            ) : (
              <></>
            )}
          </BigBanItemImage>
        </BigBanItemContainer>
      );
    }

    return (
      <BigBanItemContainer
        draggable={!!champ}
        onDragStart={(event) => handleDragStart(event, champ, index)}
        onDragOver={handleDragOver}
        onDrop={(event) => handleDrop(event, index)}
        $isHighlighted={isHighlighted ? isHighlighted : false}
        onClick={onClick && (() => onClick(index))}
      >
        <BigBanItemImage
          team={team}
          isChampion={confirmedChamp || furtherChamp ? true : false}
        >
          {confirmedChamp ? (
            <img src={confirmedChamp} alt={confirmedChamp} width={'100%'} />
          ) : furtherChamp ? (
            <img
              style={{
                opacity: 0.5,
              }}
              src={furtherChamp}
              alt={furtherChamp}
              width={'100%'}
            />
          ) : (
            <></>
          )}
        </BigBanItemImage>
      </BigBanItemContainer>
    );
  }
);

const SmallBanItem = ({
  team,
  isHighlighted,
  selectedChamp,
  confirmedChamp,
  furtherChamp,
}: {
  team: string;
  isHighlighted?: boolean;
  selectedChamp?: string | null;
  confirmedChamp?: string | null;
  furtherChamp?: string | null;
}) => {
  if (isHighlighted) {
    return (
      <SmallBanItemContainer
        $isHighlighted={isHighlighted ? isHighlighted : false}
      >
        <SamllBanItemImage
          team={team}
          isChampion={selectedChamp ? true : false}
        >
          {selectedChamp ? (
            <img src={'selectedChamp'} alt={selectedChamp} width={'100%'} />
          ) : (
            <></>
          )}
        </SamllBanItemImage>
      </SmallBanItemContainer>
    );
  }
  return (
    <SmallBanItemContainer
      $isHighlighted={isHighlighted ? isHighlighted : false}
    >
      <SamllBanItemImage
        team={team}
        isChampion={confirmedChamp || furtherChamp ? true : false}
      >
        {confirmedChamp ? (
          <img
            src={confirmedChamp}
            alt={confirmedChamp}
            width={'100%'}
            style={{
              filter: 'grayscale(1)',
            }}
          />
        ) : furtherChamp ? (
          <img
            style={{
              opacity: 0.5,
              filter: 'grayscale(1)',
            }}
            src={furtherChamp}
            alt={furtherChamp}
            width={'100%'}
          />
        ) : (
          <></>
        )}
      </SamllBanItemImage>
    </SmallBanItemContainer>
  );
};

const BanResultSection = observer(({ width, vm }: Props) => {
  const containerWidth = width ? width : '100%';

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    champ: Champ | null,
    sourceIndex: number
  ) => {
    if (champ) {
      event.dataTransfer.setData('champ', JSON.stringify(champ));
      event.dataTransfer.setData('sourceIndex', sourceIndex.toString());
      event.dataTransfer.effectAllowed = 'move';
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    // Prevent default to allow drop
    event.preventDefault();
  };

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    targetIndex: number
  ) => {
    event.preventDefault();
    const champData = event.dataTransfer.getData('champ');
    const sourceIndexData = event.dataTransfer.getData('sourceIndex');

    if (champData && sourceIndexData) {
      const sourceChamp = JSON.parse(champData) as Champ;
      const sourceIndex = parseInt(sourceIndexData, 10);

      // Swap the champions in the slots
      vm.swapChampionsInSlots(sourceIndex, targetIndex);
    }
  };

  if (vm.draftboardState.positionActivated) {
    return (
      <SectionContainer width={containerWidth}>
        <ItemContainer2>
          <SmallBanItem
            team={'blue'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.bluebantop?.image
            }
          />
          <SmallBanItem
            team={'blue'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.bluebanjungle?.image
            }
          />
          <SmallBanItem
            team={'blue'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.bluebanmid?.image
            }
          />
          <SmallBanItem
            team={'blue'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.bluebanadc?.image
            }
          />
          <SmallBanItem
            team={'blue'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.bluebansupport?.image
            }
          />
        </ItemContainer2>

        <ItemContainer>
          <BigBanItem
            team={'blue'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.bluepicktop?.image
            }
            index={0}
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            champ={vm.draftboardState.champTemplateBasic.bluepicktop || null}
          />
          <BigBanItem
            team={'blue'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.bluepickjungle?.image
            }
            index={1}
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            champ={vm.draftboardState.champTemplateBasic.bluepickjungle || null}
          />
          <BigBanItem
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            team={'blue'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.bluepickmid?.image
            }
            index={2}
            champ={vm.draftboardState.champTemplateBasic.bluepickmid || null}
          />
          <BigBanItem
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            team={'blue'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.bluepickadc?.image
            }
            index={3}
            champ={vm.draftboardState.champTemplateBasic.bluepickadc || null}
          />
          <BigBanItem
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            team={'blue'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.bluepicksupport
                ?.image
            }
            index={4}
            champ={
              vm.draftboardState.champTemplateBasic.bluepicksupport || null
            }
          />
        </ItemContainer>
        <ItemContainer2>
          <SmallBanItem
            team={'red'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.redbantop?.image
            }
          />
          <SmallBanItem
            team={'red'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.redbanjungle?.image
            }
          />
          <SmallBanItem
            team={'red'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.redbanmid?.image
            }
          />
          <SmallBanItem
            team={'red'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.redbanadc?.image
            }
          />
          <SmallBanItem
            team={'red'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.redbansupport?.image
            }
          />
        </ItemContainer2>
        <ItemContainer>
          <BigBanItem
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            team={'red'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.redpicktop?.image
            }
            index={5}
            champ={vm.draftboardState.champTemplateBasic.redpicktop || null}
          />
          <BigBanItem
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            team={'red'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.redpickjungle?.image
            }
            index={6}
            champ={vm.draftboardState.champTemplateBasic.redpickjungle || null}
          />
          <BigBanItem
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            team={'red'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.redpickmid?.image
            }
            index={7}
            champ={vm.draftboardState.champTemplateBasic.redpickmid || null}
          />
          <BigBanItem
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            team={'red'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.redpickadc?.image
            }
            index={8}
            champ={vm.draftboardState.champTemplateBasic.redpickadc || null}
          />
          <BigBanItem
            handleDragStart={handleDragStart}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            team={'red'}
            isHighlighted={undefined}
            confirmedChamp={
              vm.draftboardState.champTemplateWithPosition.redpicksupport?.image
            }
            index={9}
            champ={vm.draftboardState.champTemplateBasic.redpicksupport || null}
          />
        </ItemContainer>
      </SectionContainer>
    );
  }

  return (
    <SectionContainer width={containerWidth}>
      <ItemContainer2>
        <SmallBanItem
          team={'blue'}
          isHighlighted={vm.parentState.turn === 0}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.bluebantop?.image
          }
        />
        <SmallBanItem
          team={'blue'}
          isHighlighted={vm.parentState.turn === 2}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.bluebanjungle?.image
          }
        />
        <SmallBanItem
          team={'blue'}
          isHighlighted={vm.parentState.turn === 4}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.bluebanmid?.image
          }
        />
        <SmallBanItem
          team={'blue'}
          isHighlighted={vm.parentState.turn === 13}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.bluebanadc?.image
          }
        />
        <SmallBanItem
          team={'blue'}
          isHighlighted={vm.parentState.turn === 15}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.bluebansupport?.image
          }
        />
      </ItemContainer2>

      <ItemContainer>
        <BigBanItem
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          team={'blue'}
          isHighlighted={vm.parentState.turn === 6}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.bluepicktop?.image
          }
          index={0}
          onClick={(index) =>
            vm.setChosenChampionPos({ selectedPosition: index })
          }
          champ={vm.draftboardState.champTemplateBasic.bluepicktop || null}
        />
        <BigBanItem
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          team={'blue'}
          isHighlighted={vm.parentState.turn === 9}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.bluepickjungle?.image
          }
          index={1}
          onClick={(index) =>
            vm.setChosenChampionPos({ selectedPosition: index })
          }
          champ={vm.draftboardState.champTemplateBasic.bluepickjungle || null}
        />
        <BigBanItem
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          team={'blue'}
          isHighlighted={vm.parentState.turn === 10}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.bluepickmid?.image
          }
          index={2}
          onClick={(index) =>
            vm.setChosenChampionPos({ selectedPosition: index })
          }
          champ={vm.draftboardState.champTemplateBasic.bluepickmid || null}
        />
        <BigBanItem
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          team={'blue'}
          isHighlighted={vm.parentState.turn === 17}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.bluepickadc?.image
          }
          index={3}
          onClick={(index) =>
            vm.setChosenChampionPos({ selectedPosition: index })
          }
          champ={vm.draftboardState.champTemplateBasic.bluepickadc || null}
        />
        <BigBanItem
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          team={'blue'}
          isHighlighted={vm.parentState.turn === 18}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.bluepicksupport?.image
          }
          index={4}
          onClick={(index) =>
            vm.setChosenChampionPos({ selectedPosition: index })
          }
          champ={vm.draftboardState.champTemplateBasic.bluepicksupport || null}
        />
      </ItemContainer>
      <ItemContainer2>
        <SmallBanItem
          team={'red'}
          isHighlighted={vm.parentState.turn === 1}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.redbantop?.image
          }
        />
        <SmallBanItem
          team={'red'}
          isHighlighted={vm.parentState.turn === 3}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.redbanjungle?.image
          }
        />
        <SmallBanItem
          team={'red'}
          isHighlighted={vm.parentState.turn === 5}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.redbanmid?.image
          }
        />
        <SmallBanItem
          team={'red'}
          isHighlighted={vm.parentState.turn === 12}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.redbanadc?.image
          }
        />
        <SmallBanItem
          team={'red'}
          isHighlighted={vm.parentState.turn === 14}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.redbansupport?.image
          }
        />
      </ItemContainer2>
      <ItemContainer>
        <BigBanItem
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          team={'red'}
          isHighlighted={vm.parentState.turn === 7}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.redpicktop?.image
          }
          index={5}
          onClick={(index) =>
            vm.setChosenChampionPos({ selectedPosition: index })
          }
          champ={vm.draftboardState.champTemplateBasic.redpicktop || null}
        />
        <BigBanItem
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          team={'red'}
          isHighlighted={vm.parentState.turn === 8}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.redpickjungle?.image
          }
          index={6}
          onClick={(index) =>
            vm.setChosenChampionPos({ selectedPosition: index })
          }
          champ={vm.draftboardState.champTemplateBasic.redpickjungle || null}
        />
        <BigBanItem
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          team={'red'}
          isHighlighted={vm.parentState.turn === 11}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.redpickmid?.image
          }
          index={7}
          onClick={(index) =>
            vm.setChosenChampionPos({ selectedPosition: index })
          }
          champ={vm.draftboardState.champTemplateBasic.redpickmid || null}
        />
        <BigBanItem
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          team={'red'}
          isHighlighted={vm.parentState.turn === 16}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.redpickadc?.image
          }
          index={8}
          onClick={(index) =>
            vm.setChosenChampionPos({ selectedPosition: index })
          }
          champ={vm.draftboardState.champTemplateBasic.redpickadc || null}
        />
        <BigBanItem
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          team={'red'}
          isHighlighted={vm.parentState.turn === 19}
          confirmedChamp={
            vm.draftboardState.champTemplateBasic.redpicksupport?.image
          }
          index={9}
          onClick={(index) =>
            vm.setChosenChampionPos({ selectedPosition: index })
          }
          champ={vm.draftboardState.champTemplateBasic.redpicksupport || null}
        />
      </ItemContainer>
    </SectionContainer>
  );
});

export default BanResultSection;
