import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from '../../../../../components/common/input/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  width: 30vw;
  align-items: center;
  background: #f6f6f6;
`;

const SearchBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;

const SearchButton = styled.div`
  padding: 10px;
  font-size: 28px;
  color: white;
  background-color: transparent;
  border: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
`;

const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 62px;
  margin-right: 10px;
`;

const CustomInput = styled(Input)`
  background: transparent;
  font-size: 1.3rem;
  padding: 5px;
  color: white;
  width: 200px;
  border-bottom: 1px solid black;
  margin-left: 10px;
`;

export const SearchButtonBar = ({
  searchTerm,
  setSearchTerm,
}: {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}) => {
  return (
    <SearchBar>
      <SearchInputWrapper>
        <SearchButton>
          <FontAwesomeIcon icon={faSearch} style={{ color: 'black' }} />
        </SearchButton>
        <CustomInput
          placeholder="로스터 이름 검색..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ color: 'black' }}
        />
      </SearchInputWrapper>
    </SearchBar>
  );
};
