import styled from "styled-components";
import { TitleWithGoBack } from "../Title";
import { ProfileArea } from "../ProfileArea";
import { Label } from "../../../../components/common/input/Input";
import { PasswordInput } from "../../../../components/common/input/PasswordInput";
import { ContinueButton } from "../../../../components/common/button/ContinueButton";
import { useState } from "react";
import { useAuth } from "../../../../hooks/context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const ResetPasswordBox = styled.div`
  background: white;
    padding: 60px;
  box-sizing: border-box;
  border-radius: 0px 0px 8px 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
   max-width: 600px;
  text-align: left;
  display: flex;
  flex-direction: column;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 500px;
    justify-content: flex-end;
    width: 100%;
`;
const Notification = styled.div`
  background: #20E600;
  border-radius: 8px 8px 0px 0px;
  padding: 10px;
  color: white;
  text-align: left;
    display: flex;
  align-items: center;
`;
const CircleIcon = styled(FontAwesomeIcon)`
    font-size: 10px;
    margin-right: 5px;
`;
export const ResetPassword2 = () => {
    const [isPasswordChange, setIsPasswordChange] = useState(false);
    const {changePassword, setChangePassword, setChangePasswordConfirm, changePasswordConfirm} = useAuth();
    return (
        <Container>
             {isPasswordChange && <Notification><CircleIcon icon={faCircle}/>비밀번호가 성공적으로 변경되었습니다!</Notification>}
            <ResetPasswordBox>
                <TitleWithGoBack title="비밀번호 변경" />
                <ProfileArea />
                <Label>변경할 비밀번호</Label>
                <PasswordInput onChange={(e) => setChangePassword(e.target.value)} />
                <Label>비밀번호 확인</Label>
                <PasswordInput onChange={(e) => setChangePasswordConfirm(e.target.value)} />
                <ContinueButton onClick={() => setIsPasswordChange(true)} title="계속하기"/>
            </ResetPasswordBox>
        </Container>
    )
}