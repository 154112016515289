import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  useRoaster,
  RoasterProvider,
} from '../../../../../hooks/context/RoasterContext';
import { RoasterContainer } from '../../../../../components/RoasterContainer';
import { RoasterData } from '../../../../userdata/roaster/type';
import Spinner from '../../../../../components/common/loading';
import { SearchButtonBar } from './TooltipSearch';

const TeamButton = styled.button`
  background: #000;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;
  position: relative;
  &:hover {
    background: #333;
  }
  font-size: 0.8vw;
`;

const Tooltip = styled.div<{ team: string; top: number; left: number }>`
  position: fixed;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  transform: translate(-50%, 25px); /* 버튼 아래로 25px 이동 */
  background: #fff;
  border-radius: 16px;
  background: ${({ team }) => (team === 'blue' ? '#79DFFF' : '#FF7979')};
  box-shadow: 0px 0px 20px 6px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  width: 700px;
  box-sizing: border-box;
  padding: 20px;

  &::before {
    content: '';
    position: absolute;
    top: -10px; /* 삼각형을 위쪽에 배치 */
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 10px 10px 10px; /* 삼각형 크기 조정 */
    border-style: solid;
    border-color: ${({ team }) =>
      team === 'blue'
        ? 'transparent transparent #79DFFF transparent'
        : 'transparent transparent #FF7979 transparent'}; /* 삼각형 색상 설정 */
  }

  &::after {
    content: '';
    position: absolute;
    top: -20px; /* 삼각형을 위쪽에 배치 */
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 16px 16px 16px; /* 삼각형 크기 조정 */
    border-style: solid;
    border-color: ${({ team }) =>
      team === 'blue'
        ? 'transparent transparent #79DFFF transparent'
        : 'transparent transparent #FF7979 transparent'}; /* 삼각형 색상 설정 */
  }
`;

const ScrollContainer = styled.div`
  height: 420px;
  overflow-y: auto;
  padding-right: 10px; /* Optional: to avoid the content being hidden by the scrollbar */
  padding-bottom: 10px; /* Optional: to avoid the content being hidden by the scrollbar */
  display: flex;
  flex-direction: column;
  gap: 20px; /* 아이템 사이의 간격 추가 */

  /* Scrollbar styles */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
    height: 20%;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #ccc;
  }
`;
const TooltipButtonContent = ({
  team,
  onClick,
}: {
  team: string;
  onClick: (roasterData: RoasterData) => void;
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef<HTMLButtonElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const { roasterDataList, isAllRoasterLoading } = useRoaster();
  const [searchTerm, setSearchTerm] = useState('');

  const handleButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.bottom,
        left: rect.left + rect.width / 2,
      });
    }
    setIsTooltipVisible((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    event.stopPropagation();
    if (
      tooltipRef.current &&
      tooltipRef.current.contains(event.target as Node)
    ) {
      return;
    }

    if (buttonRef.current && buttonRef.current.contains(event.target as Node)) {
      return;
    }

    setIsTooltipVisible(false);
  };

  useEffect(() => {
    if (isTooltipVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isTooltipVisible]);

  // 검색어에 따라 로스터 리스트 필터링
  const filteredRoasterDataList = roasterDataList.filter((roaster) =>
    roaster.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <TeamButton onClick={handleButtonClick} ref={buttonRef}>
      Team
      {isTooltipVisible && (
        <Tooltip
          ref={tooltipRef}
          team={team}
          top={tooltipPosition.top}
          left={tooltipPosition.left}
          onClick={(e) => e.stopPropagation()}
        >
          <SearchButtonBar
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
          <ScrollContainer>
            {isAllRoasterLoading ? (
              <Spinner />
            ) : (
              filteredRoasterDataList.map((roasterData, index) => {
                return (
                  <RoasterContainer
                    key={index}
                    roasterData={roasterData}
                    onRoasterPlayerChange={() => {}}
                    index={index}
                    editable={false}
                    onClick={() => {
                      onClick(roasterData);
                      setIsTooltipVisible(false);
                    }}
                    showMinus={false}
                    forcingNotEditable={true}
                  />
                );
              })
            )}
          </ScrollContainer>
        </Tooltip>
      )}
    </TeamButton>
  );
};

const TooltipButton = ({
  team,
  onClick,
}: {
  team: string;
  onClick: (roasterData: RoasterData) => void;
}) => {
  return (
    <RoasterProvider>
      <TooltipButtonContent team={team} onClick={onClick} />
    </RoasterProvider>
  );
};

export default TooltipButton;
