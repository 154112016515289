import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input, Label } from '../../../../components/common/input/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { usePlayer } from '../../../../hooks/context/PlayerContext';

const LayerBlur = styled.div`
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  left: calc(50vw - 300px);
  top: calc(30% - 170px);

  width: 600px;
  height: 340px;
  z-index: 100;
  display: flex;
  color: black;
  font-size: 1.5rem;

  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  backdrop-filter: blur(6px);

  border-radius: 16px;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  flex: 1;
  min-width: 330px;
  max-width: 700px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const Select = styled.select`
  padding: 0.75rem;
  border: none;
  border-bottom: 2px solid #ddd;
  background: transparent;
  border-radius: 4px;
  font-size: 1rem;
  height: 46px; /* 추가된 높이 설정 */
`;

const Button = styled.button`
  padding: 0.4rem 1rem;
  border: none;
  background: #0057ff;
  border-radius: 16px;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  align-self: flex-start;
  margin-top: 1rem;

  &:hover {
    background: #0047d4;
  }
`;

const CustomInput2 = styled(Input)`
  background: transparent;
  padding: 0;
  text-align: left;
  width: 60%;
  font-size: 1.2rem;
  height: 46px; /* 추가된 높이 설정 */
  box-sizing: border-box; /* 박스 사이징 설정 */
`;

const CustomInput3 = styled(Input)`
  background: transparent;
  padding: 0;
  text-align: left;
  width: 100%;
  font-size: 1.2rem;
  margin-bottom: 1rem;
`;

const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
  padding-bottom: 1rem;
  font-size: 24px;
  align-self: center;
`;

const IdGroup = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const TeamLineGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const AccountList = styled.div`
  height: 65px;
  max-height: 65px;
  width: 520px;
  box-sizing: border-box;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 8px;

  /* Scrollbar styles */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
    height: 20%;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #ccc;
  }
`;

const AccountItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }
`;

const AccoutButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
`;

const PositionSelect = styled(Select)`
  width: 100%;
`;

const SimpleInstruction = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

export const PlayerInfoFormSection = () => {
  const {
    index,
    playerName,
    playerProfileImage,
    playerTeam,
    setPlayerTeam,
    playerLeague,
    setPlayerLeague,
    playerPosition,
    setPlayerPosition,
    playerDebut,
    setPlayerDebut,
    riot_id,
    setRiotId,
    region,
    setRegion,
    riotAccount,
    setRiotAccount,
    setPlayerList,
    fetchPlayerList,
    playerList,
    handleSavePlayer,
    changeSyncronized,
  } = usePlayer();

  useEffect(() => {
    setPlayerTeam('');
    setPlayerPosition('');
    setPlayerDebut('');
    setPlayerLeague('');
    setRiotAccount([]);
  }, [
    fetchPlayerList,
    setPlayerTeam,
    setPlayerPosition,
    setPlayerDebut,
    setPlayerLeague,
    setRiotAccount,
  ]);

  const handleAddAccount = () => {
    if (!index) return;
    if (!riot_id) return;
    if (!riot_id.includes('#')) return;
    if (!region) return;
    const accountExists = riotAccount.some(
      (account) => account.riot_id === riot_id && account.region === region
    );
    if (accountExists) return;
    setRiotAccount([...riotAccount, { riot_id, region }]);
    changeSyncronized(playerList[index]);
    setRiotId('');
    setRegion('');
  };

  const handleTeamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!index) return;
    changeSyncronized(playerList[index]);
    setPlayerTeam(e.target.value);
  };

  const handlePositionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!index) return;
    changeSyncronized(playerList[index]);
    setPlayerPosition(e.target.value);
  };

  const handleDebutYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!index) return;
    changeSyncronized(playerList[index]);
    setPlayerDebut(e.target.value);
  };

  const handleLeagueChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!index) return;
    changeSyncronized(playerList[index]);
    setPlayerLeague(e.target.value);
  };

  function formatDate(date: string) {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const handleSave = (index: number) => {
    handleSavePlayer(playerList[index]);
  };

  const [formattedDate, setFormattedDate] = useState('');
  useEffect(() => {
    if (playerDebut) {
      setFormattedDate(formatDate(playerDebut));
    } else if (playerDebut === '') {
      setFormattedDate('');
    }
  }, [playerDebut]);

  return (
    <FormSection>
      <SimpleInstruction>
        선수 이름을 정확하게 입력해주세요. 선수 이름을 정확하게 입력하지 않은
        경우, 대회 데이터를 불러오는 데에 문제가 발생할 수 있습니다.
      </SimpleInstruction>
      {index === null && (
        <LayerBlur>
          새 프로필을 생성하거나,
          <br />
          수정할 프로필을 아래에서 선택해주세요.
        </LayerBlur>
      )}
      <FormGroup>
        <Label>소속 팀</Label>
        <TeamLineGroup>
          <CustomInput2
            title="Team"
            value={playerTeam || ''}
            onChange={handleTeamChange}
          />
          <FormGroup>
            <PositionSelect
              value={playerLeague || ''}
              onChange={handleLeagueChange}
            >
              <option value="" disabled>
                리그 선택...
              </option>
              <option value="LCK">LCK</option>
              <option value="LPL">LPL</option>
              <option value="LEC">LEC</option>
              <option value="LCS">LCS</option>
            </PositionSelect>
          </FormGroup>
          <FormGroup>
            <PositionSelect
              value={playerPosition || ''}
              onChange={handlePositionChange}
            >
              <option value="" disabled>
                포지션 선택 ...
              </option>
              <option value="top">Top</option>
              <option value="jungle">Jungle</option>
              <option value="mid">Mid</option>
              <option value="adc">Adc</option>
              <option value="support">Support</option>
            </PositionSelect>
          </FormGroup>
        </TeamLineGroup>
      </FormGroup>
      <FormGroup>
        <Label>데뷔년도</Label>
        <TeamLineGroup>
          <CustomInput2
            title="Debut Year"
            type="date"
            value={formattedDate}
            onChange={handleDebutYearChange}
          />
        </TeamLineGroup>
      </FormGroup>
      <FormGroup>
        <Label>라이엇 ID (태그 포함)</Label>
        <IdGroup>
          <CustomInput3
            placeholder="Riot ID"
            value={riot_id}
            onChange={(e) => setRiotId(e.target.value)}
          />
          <FormGroup>
            <Select value={region} onChange={(e) => setRegion(e.target.value)}>
              <option value="" disabled>
                리전 선택 ...
              </option>
              <option value="ASIA">ASIA</option>
              <option value="EUROPE">EUROPE</option>
              <option value="AMERICA">AMERICA</option>
            </Select>
          </FormGroup>
          <Icon icon={faCirclePlus} color="black" onClick={handleAddAccount} />
        </IdGroup>
        <AccoutButtonGroup>
          <AccountList>
            {riotAccount.length === 0 && (
              <AccountItem>계정 정보가 없습니다.</AccountItem>
            )}
            {riotAccount.map((account, index) => (
              <AccountItem key={index}>
                <span>{account.riot_id}</span>
                <span>{account.region}</span>
              </AccountItem>
            ))}
          </AccountList>
          <ButtonContainer>
            <Button
              onClick={() => {
                if (!index) return;
                handleSave(index);
              }}
            >
              {index && playerList[index]?.is_db === false
                ? '새 선수 추가'
                : '저장하기'}
            </Button>
          </ButtonContainer>
        </AccoutButtonGroup>
      </FormGroup>
    </FormSection>
  );
};
