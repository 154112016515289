import styled from 'styled-components';
import { Input } from '../../../../components/common/input/Input';
import { PlayerImage } from '../../../../components/common/icon/ProfileIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { usePlayer } from '../../../../hooks/context/PlayerContext';
import { useEffect } from 'react';
import { Roaster } from '../../../../apis/types/userDataApiTypes';
import { RoasterPlayer } from '../../roaster/type';

const ProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    margin-right: clamp(0rem, 8rem, 8vw);
    margin-bottom: 0;
  }
`;

const ProfileImageWrapper = styled.div`
  position: relative;
`;

const EditIconWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  right: 10px;
  background: white;
  border-radius: 50%;
  font-size: 28px;
  border: 1px solid #ddd;
  text-align: center;
  width: 40px;
  aspect-ratio: 1/1;
  cursor: pointer;
`;

const CustomInput = styled(Input)`
  background: transparent;
  font-size: 26px;
  width: 100%;
  max-width: 250px;
  box-sizing: border-box;
  font-weight: 600;
  padding: 0;
  text-align: center;
`;

const ClickableIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

export const ProfileImageSection = () => {
  const {
    index,
    playerName,
    setPlayerName,
    playerProfileImage,
    setPlayerProfileImage,
    playerList,
    setPlayerList,
    fetchPlayerList,
  } = usePlayer();

  useEffect(() => {
    setPlayerName('');
  }, [fetchPlayerList, setPlayerName]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlayerName(e.target.value);

    // playerList 업데이트
    setPlayerList([
      ...playerList.map((player, i) =>
        i === index
          ? ({ ...player, name: e.target.value } as RoasterPlayer)
          : player
      ),
    ]);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPlayerProfileImage(e.target?.result as string);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <ProfileSection>
      <ProfileImageWrapper>
        <PlayerImage
          src={playerProfileImage || '/pProfile.png'}
          radius={250}
          isClickOff={true}
        />
        {/* <EditIconWrapper>
          <ClickableIcon icon={faPen} width={'50%'} height={'50%'} />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              opacity: 0,
              cursor: 'pointer',
            }}
          />
        </EditIconWrapper> */}
      </ProfileImageWrapper>
      <CustomInput
        title="Player Name"
        value={playerName}
        onChange={handleNameChange}
      />
    </ProfileSection>
  );
};
