import React, { useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';

const FadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const FadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Container = styled.div<{
  $isVisible: boolean;
  $status: string;
  $isAnimating: boolean;
}>`
  display: flex;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 20%;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  background: ${(props) =>
    props.$status === 'complete' ? '#6666ffee' : '#ff6666ee'};
  color: black;
  padding: 20px;
  border-radius: 16px;
  z-index: 999;
  display: ${(props) => (props.$isAnimating ? 'block' : 'none')};
  ${(props) =>
    props.$isAnimating
      ? css`
          animation: ${props.$isVisible ? FadeIn : FadeOut} 0.2s forwards;
        `
      : ''};
`;

interface CenterNotificationProps {
  noti: {
    status: string;
    message: string;
    isVisible: boolean;
    isAnimating: boolean;
  };
  setNoti: (noti: {
    status: string;
    message: string;
    isVisible: boolean;
    isAnimating: boolean;
  }) => void;
}

const CenterNotification = ({ noti }: CenterNotificationProps) => {
  const { status, message, isVisible, isAnimating } = noti;

  return (
    <Container
      $isVisible={isVisible}
      $status={status}
      $isAnimating={isAnimating}
    >
      <div>{message}</div>
    </Container>
  );
};

export default CenterNotification;
