import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2vw; /* 반응형 간격 설정 */
  width: 100%;
  justify-content: space-between;
`;

const TitleButton = styled.div`
  font-size: 4vw; /* 반응형 폰트 크기 설정 */
  font-weight: 900;
  padding: 2vw; /* 반응형 패딩 설정 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #333;
  color: white;
  border-radius: 8px;
  border: none;
  text-align: center;
  white-space: nowrap; /* 줄바꿈 방지 */
  @media (min-width: 768px) {
    font-size: 2vw; /* 고정된 폰트 크기 설정 */
    padding: 1rem; /* 고정된 패딩 설정 */
  }
`;

const NextButton = styled.button<{ isAllFilled: boolean }>`
  background: ${({ isAllFilled }) => (isAllFilled ? '#00de9b' : '#484848')};
  border-radius: 12px;
  color: white;
  font-size: 6vw; /* 반응형 폰트 크기 설정 */
  font-weight: 600;
  border: none;
  padding: 2vw 6vw; /* 반응형 패딩 설정 */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    font-size: 2vw; /* 고정된 폰트 크기 설정 */
    padding: 7px 25px; /* 고정된 패딩 설정 */
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw; /* 반응형 간격 설정 */
  padding: 0 2vw; /* 반응형 패딩 설정 */
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 50%; /* 원하는 길이로 설정 */
    background-color: #a0a0a0;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
`;

const LeftButton = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 6vw; /* 반응형 폰트 크기 설정 */
  padding: 1vw 3vw; /* 반응형 패딩 설정 */
  font-weight: bold;
  color: ${({ isSelected }) =>
    isSelected ? 'rgba(68, 76, 255, 1)' : 'rgba(128, 128, 128, 0.2)'};
  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected ? 'rgba(121, 223, 255, 1)' : 'rgba(121, 223, 255, 0.2)'};
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;

  &:hover {
    background-color: rgba(121, 223, 255, 1);
  }

  @media (min-width: 768px) {
    font-size: 2vw; /* 고정된 폰트 크기 설정 */
    padding: 0.5rem 1.5rem; /* 고정된 패딩 설정 */
  }
`;

const RightButton = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 6vw; /* 반응형 폰트 크기 설정 */
  padding: 1vw 3vw; /* 반응형 패딩 설정 */
  font-weight: bold;
  color: ${({ isSelected }) =>
    isSelected ? 'rgba(255, 20, 49, 1)' : 'rgba(128, 128, 128, 0.2)'};
  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected ? 'rgba(255, 121, 121, 1)' : 'rgba(255, 121, 121, 0.2)'};
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;

  &:hover {
    background-color: rgba(255, 121, 121, 1);
  }

  @media (min-width: 768px) {
    font-size: 2vw; /* 고정된 폰트 크기 설정 */
    padding: 0.5rem 1.5rem; /* 고정된 패딩 설정 */
  }
`;

const ButtonMenu = () => {
  return (
    <Container>
      {/* <TitleButton>AI의 추천을 받을 팀을 선택해주세요:</TitleButton>
      <ButtonContainer>
        <LeftButton
          onClick={() => setSelectedTeam('blue')}
          isSelected={selectedTeam === 'blue'}
        >
          Blue
        </LeftButton>
        <RightButton
          onClick={() => setSelectedTeam('red')}
          isSelected={selectedTeam === 'red'}
        >
          Red
        </RightButton>
      </ButtonContainer>
      <NextButton onClick={handleNext} isAllFilled={isAllFilled}>
        Go <FontAwesomeIcon icon={faArrowRight} />
      </NextButton> */}
    </Container>
  );
};

export default ButtonMenu;
