import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const NoRoutes = () => {
  return <Container>404 Not Found</Container>;
};

export default NoRoutes;
