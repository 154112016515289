import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "./Input";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import styled from "styled-components";

const PasswordContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  box-sizing: border-box;
  & > input {
    width: 100%;
    padding-right: 2.5rem;
  }

  & > span {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;
interface PasswordInputProps {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
}
export const PasswordInput = ({ onChange, value }: PasswordInputProps) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <PasswordContainer>
            <Input type={showPassword ? "text" : "password"} onChange={onChange} value={value} />
            <span onClick={() => setShowPassword(!showPassword)}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </span>
        </PasswordContainer>
    )
}