import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 60px;
  height: 12px;
  display: flex;
  flex-direction: row;
`;

const Box = styled.div<{ $isBlue: boolean; $opacity: number }>`
  width: 12px;
  height: 12px;
  background-color: ${(props) => (props.$isBlue ? 'blue' : 'red')};
  opacity: ${(props) => props.$opacity};
`;

const PositionHeatMap = ({ team, data }: { team: string; data: number[] }) => {
  if (!data || Math.max(...data) === 0) {
    return <></>;
  }
  return (
    <Container>
      {data.map((d, idx) => (
        <Box
          key={idx}
          $isBlue={team === 'blue'}
          $opacity={(d * 1) / 2 / 100 + 0.3}
        />
      ))}
    </Container>
  );
};

export default PositionHeatMap;
