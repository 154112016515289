// ProtectedRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/context/AuthContext';
import { ReactElement } from 'react';
import Spinner from '../components/common/loading';
import { useScreenWidth } from '../ScreenWidthContext';

interface ProtectedRouteProps {
  element: ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { user, isLoading } = useAuth();
  const { isScreenTooSmall } = useScreenWidth();

  if (isLoading) {
    return <Spinner />;
  }

  if (isScreenTooSmall) {
    return (
      <div
        style={{
          textAlign: 'center',
          padding: '2rem',
          fontSize: '1.5rem',
          color: 'red',
        }}
      >
        낮은 해상도에서는 접속이 불가능합니다.
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/auth" />;
  }

  return element;
};

export default ProtectedRoute;
