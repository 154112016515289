import React from 'react';
import styled from 'styled-components';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { observer } from 'mobx-react';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // 배경 반투명 처리
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 100;
`;

const SimulateSetterContainer = styled.div`
  display: flex;
  padding: 15px;
  box-sizing: border-box;
  flex-direction: column;

  background-color: #282828;
  gap: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.15);

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;

  font-size: 14px;

  width: 160px;
  height: 236px;
  color: #fafafa;
`;

const SimulateChooseButton = styled.div<{ $isActivated?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 1.5%;
  width: 132px;
  height: 38px;
  box-sizing: border-box;

  background: ${(props) =>
    props.$isActivated !== undefined && props.$isActivated === false
      ? 'rgba(126, 64, 255, 0.2)'
      : 'rgba(126, 64, 255, 1)'};
  box-shadow: ${(props) =>
    props.$isActivated !== undefined && props.$isActivated === false
      ? 'none'
      : '0px 0px 4px 2px rgba(126,62,255, 0.5)'};
  border-radius: 6px;

  font-family: 'Pretendard';
  font-style: normal;
  font-size: 1em;
  text-align: center;

  color: ${(props) =>
    props.$isActivated !== undefined && props.$isActivated === false
      ? '#727272'
      : '#fafafa'};
  cursor: 'pointer';
`;

const SimulateSetter = observer(
  ({ vm, onClose }: { vm: any; onClose: () => void }) => {
    return (
      <Overlay onClick={onClose}>
        <SimulateSetterContainer>
          <div>시뮬레이션 정책</div>
          <SimulateChooseButton $isActivated={false}>
            랜덤 선택
          </SimulateChooseButton>
          <SimulateChooseButton $isActivated={true}>
            최적화 선택
          </SimulateChooseButton>
          <SimulateChooseButton $isActivated={false}>
            최적화 선택
          </SimulateChooseButton>
        </SimulateSetterContainer>
      </Overlay>
    );
  }
);

const CustomSlider = ({
  v,
  setV,
  minv,
  maxv,
}: {
  v: number;
  setV: (value: number) => void;
  minv: number;
  maxv: number;
}) => {
  const handleChange = (value: number | number[]) => {
    if (typeof value === 'number') {
      setV(value);
    }
  };

  return (
    <div
      style={{
        width: '80%',
      }}
    >
      <Slider min={minv} max={maxv} value={v} onChange={handleChange} />
    </div>
  );
};

const KpSetterContainer = styled.div`
  display: flex;
  padding: 15px;
  box-sizing: border-box;
  flex-direction: column;

  background-color: #f6f6f6;
  gap: 15px;
  align-items: center;
  justify-content: center;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.15);

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;

  font-size: 14px;

  width: 160px;
  height: 236px;
  color: #121212;
`;

const KpSetter = observer(
  ({ vm, onClose }: { vm: any; onClose: () => void }) => {
    return (
      <Overlay onClick={onClose}>
        <KpSetterContainer>
          <div
            style={{
              fontWeight: 600,
              fontSize: '20px',
            }}
          >
            k={vm.kvalue}
          </div>
          <div>픽할 확률이 높은 {vm.kvalue}개의 값만 추출합니다.</div>
          <CustomSlider v={vm.kvalue} setV={vm.setKValue} minv={1} maxv={20} />

          <div
            style={{
              fontWeight: 600,
              fontSize: '20px',
            }}
          >
            p={vm.pvalue}%
          </div>
          <div>기대 승률이 {vm.pvalue}% 이상인 값만 추출합니다.</div>
          <CustomSlider v={vm.pvalue} setV={vm.setPValue} minv={50} maxv={60} />
        </KpSetterContainer>
      </Overlay>
    );
  }
);

export { SimulateSetter, KpSetter };
