import styled from 'styled-components';

export const PlayerCard = styled.div<{ maxwidth: number }>`
  display: flex;
  min-width: ${({ maxwidth }) => `${maxwidth}px`};
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  background: none;
`;

interface PlayerImageProps {
  radius: number;
  highlighted?: boolean;
  isClickOff?: boolean;
  isGlowing?: boolean;
  isBlack?: boolean;
}

export const PlayerImage = styled.img<PlayerImageProps>`
  width: ${(props) => props.radius}px;
  height: ${(props) => props.radius}px;
  border: ${({ highlighted }) =>
    highlighted ? '1.5px solid yellow' : '1px solid #ccc'};
  filter: ${(props) =>
    props.highlighted && !props.isBlack
      ? 'drop-shadow(0px 0px 8px rgba(0, 200, 200, 0.8))'
      : props.isBlack
      ? 'grayscale(100%)'
      : 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))'};

  border-radius: 50%;
  object-fit: cover;
  background: white;
  aspect-ratio: 1/1;
  cursor: ${(props) =>
    props.isClickOff === false || props.isClickOff === undefined
      ? 'pointer'
      : 'default'};

  /* glow effect */
  @keyframes glowing {
    0% {
      box-shadow: 0 0 5px 0px rgba(255, 255, 0, 0.75);
    }
    50% {
      box-shadow: 0 0 10px 6px rgba(255, 255, 0, 0.75);
    }
    100% {
      box-shadow: 0 0 5px 0px rgba(255, 255, 0, 0.75);
    }
  }

  animation: ${(props) =>
    props.isGlowing ? 'glowing 1000ms infinite' : 'none'};

  @media (max-width: 1200px) {
    width: ${(props) => props.radius / 2}px;
    height: ${(props) => props.radius / 2}px;
  }
`;

export const PlayerName = styled.div<{ maxwidth: number }>`
  font-size: 0.8rem;
  text-align: center;
  font-family: 'Pretendard';
  max-height: 26px;
  box-sizing: border-box;
  cursor: pointer;

  text-overflow: ellipsis;
  justify-content: center;
  align-items: center;

  @media (max-width: 1200px) {
    font-size: 0.7rem;
    text-overflow: ellipsis;
  }
`;

const RoleImageContainer = styled.div<{
  highlighted: boolean;
  isClickOff: boolean;
}>`
  background: white;
  border-radius: 50%;
  border: ${({ highlighted }) =>
    highlighted ? '1.5px solid #f88' : '1px solid #ccc'};
  filter: ${({ highlighted }) =>
    highlighted
      ? 'drop-shadow(0px 0px 8px rgba(250, 96, 96, 0.4))'
      : 'drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25))'};
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 34px;
  cursor: ${(props) =>
    props.isClickOff === false || props.isClickOff === undefined
      ? 'pointer'
      : 'default'};
`;

const RoleImage = styled.img`
  width: 80%;
  height: 80%;
  object-fit: cover;
`;
interface PlayerRoleProps {
  radius: number;
  imageSrc: string;
  alt: string;
  onClick: () => void;
  highlighted: boolean;
  isClickOff?: boolean;
  isGlowing?: boolean;
}
export const PlayerRole: React.FC<PlayerRoleProps> = ({
  radius,
  imageSrc,
  alt,
  onClick,
  highlighted,
  isClickOff = false,
  isGlowing = false,
}) => {
  return (
    <RoleImageContainer
      style={{ width: `${radius}px`, height: `${radius}px` }}
      onClick={onClick}
      highlighted={highlighted}
      isClickOff={isClickOff}
    >
      <RoleImage src={imageSrc} alt={alt} />
    </RoleImageContainer>
  );
};
export const ImageWithName = ({
  image,
  name,
  radius,
  onClick,
  highlighted = false,
  isClickOff = false,
  isGlowing = false,
  isBlack = false,
}: {
  image?: string;
  name: string;
  radius: number;
  onClick?: () => void;
  highlighted?: boolean;
  isClickOff?: boolean;
  isGlowing?: boolean;
  isBlack?: boolean;
}) => {
  if (!image) {
    image = '/pProfile.png';
  }
  return (
    <PlayerCard onClick={onClick} maxwidth={radius}>
      <PlayerImage
        src={image}
        alt={name}
        radius={radius}
        highlighted={highlighted}
        isClickOff={isClickOff || isBlack}
        isGlowing={isGlowing}
        isBlack={isBlack}
      />
      <PlayerName maxwidth={radius}>{name}</PlayerName>
    </PlayerCard>
  );
};
