import { MenuArea } from '../../../components/mainMenu/MenuArea';
import { PlayerProvider } from '../../../hooks/context/PlayerContext';
import { RoasterProvider } from '../../../hooks/context/RoasterContext';
import { PlayerDetailArea } from './PlayerDetailArea';

const UserDataPlayer = () => {
  return (
    <PlayerProvider>
      <MenuArea
        title="선수 정보 관리"
        selectedItem="선수 정보 관리"
        background="#F6F6F6"
      >
        <PlayerDetailArea />
      </MenuArea>
    </PlayerProvider>
  );
};

export default UserDataPlayer;
