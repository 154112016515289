import { MenuArea } from '../../../components/mainMenu/MenuArea';
import { UserDataProvider } from '../../../hooks/context/UserDataContext';
import { EditProfileArea } from './EditProfileArea';

const UserDataPrivateContent = () => {
  return (
    <MenuArea
      title="계정 정보 변경"
      selectedItem="계정 정보 변경"
      initialOpen={true}
    >
      <EditProfileArea />
    </MenuArea>
  );
};

const UserDataPrivate = () => {
  return (
    <UserDataProvider>
      <UserDataPrivateContent />
    </UserDataProvider>
  );
};

export default UserDataPrivate;
