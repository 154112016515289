import {
  GetUserDataResponse,
  PutPlayerRequest,
  EditPlayerRequest,
  DropPlayerRequest,
  GetPlayerRequest,
  GetOnePlayerRequest,
  PlayerResponse,
  PutRoasterRequest,
  EditRoasterRequest,
  DropRoasterRequest,
  RoasterResponse,
  PlayerImageSaveRequest,
  PlayerImageSaveResponse,
} from './types/userDataApiTypes';
import apiClient from './apiClient';

export const getUserData = async (): Promise<GetUserDataResponse> => {
  try {
    const response = await apiClient.get('/userdata/getUserData');
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 선수 관련 API
export const putPlayer = async (
  data: PutPlayerRequest
): Promise<PlayerResponse> => {
  try {
    const response = await apiClient.post('/userdata/putPlayer', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editPlayer = async (
  data: EditPlayerRequest
): Promise<PlayerResponse> => {
  try {
    const response = await apiClient.post('/userdata/editPlayer', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPlayerBase = async (): Promise<PlayerResponse> => {
  try {
    const response = await apiClient.get('/userdata/getPlayerBase');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPlayer = async (
  params: GetPlayerRequest
): Promise<PlayerResponse> => {
  try {
    const response = await apiClient.get('/userdata/getPlayer', { params });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getOnePlayer = async (
  data: GetOnePlayerRequest
): Promise<PlayerResponse> => {
  try {
    const response = await apiClient.get('/userdata/getOnePlayer', {
      params: data,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const dropPlayer = async (
  data: DropPlayerRequest
): Promise<PlayerResponse> => {
  try {
    const response = await apiClient.post('/userdata/dropPlayer', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// 로스터 관련 API
export const putRoaster = async (
  data: PutRoasterRequest
): Promise<RoasterResponse> => {
  try {
    const response = await apiClient.post('/userdata/putRoaster', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editRoaster = async (
  data: EditRoasterRequest
): Promise<RoasterResponse> => {
  try {
    const response = await apiClient.post('/userdata/editRoaster', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getRoaster = async (): Promise<RoasterResponse> => {
  try {
    const response = await apiClient.get('/userdata/getRoaster');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const dropRoaster = async (
  data: DropRoasterRequest
): Promise<RoasterResponse> => {
  try {
    const response = await apiClient.post('/userdata/dropRoaster', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const playerImageSaveDB = async (
  data: PlayerImageSaveRequest
): Promise<PlayerImageSaveResponse> => {
  try {
    const formData = new FormData();
    formData.append('img', data.imageUrl);
    formData.append('playerInfo', JSON.stringify(data.playerInfo));

    const response = await apiClient.post('/userdata/playerImgUp', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
