import styled from 'styled-components';
import { PlayerInfoFormSection } from './component/PlayerInfoFormSection';
import { ProfileImageSection } from './component/ProfileImageSection';
import { SearchPlayer } from '../../../components/search/SearchPlayer';
import { usePlayer } from '../../../hooks/context/PlayerContext';
import useSectionHeight from '../../../hooks/useSectionHeight';
import { PlayerData, RoasterPlayer } from '../roaster/type';
import CenterNotification from '../../../components/common/centerNotification';
import { useEffect } from 'react';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  justify-content: center;
  align-items: center;
`;

const DynamicSearchContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  align-self: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
  overflow: hidden;
`;

const BackgroundDiv = styled.div`
  background: #f6f6f6;
  flex: 1;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const PlayerDetailArea = () => {
  const { updatePlayer, noti, setNoti, isLoading, filteredPlayers } =
    usePlayer();
  const { sectionRef, sectionHeight } = useSectionHeight(); // 훅을 사용하여 sectionRef와 sectionHeight를 가져옵니다.

  const handlePlayerEdit = (player: RoasterPlayer, index: number) => {
    updatePlayer({ updatedPlayer: player, index });
  };

  return (
    <BackgroundDiv>
      <Container>
        <ProfileImageSection />
        <PlayerInfoFormSection />
        <CenterNotification noti={noti} setNoti={setNoti} />
      </Container>
      <DynamicSearchContainer ref={sectionRef}>
        <SearchPlayer
          buttonName="프로필 생성"
          onPlayerClick={handlePlayerEdit}
          containerHeight={`${sectionHeight}px`}
          isLoading={isLoading}
          filteredPlayers={filteredPlayers}
        />
      </DynamicSearchContainer>
    </BackgroundDiv>
  );
};
