import React from 'react';
import styled from 'styled-components';
import LargeBalloon from '../../../../../assets/largeWordBalloon.svg';
import UnClickableCheckbox from '../../../../../assets/checkBoxUnclickable.svg';
import OffCheckBox from '../../../../../assets/checkBoxOff.svg';
import OnCheckBox from '../../../../../assets/checkBoxOn.svg';
import { observer } from 'mobx-react';

const BalloonDiv = styled.div<{ $isShow: boolean }>`
  display: ${(props) => (props.$isShow ? 'block' : 'none')};

  width: 180px;
  height: 370px;
  background: url(${LargeBalloon}) no-repeat center;
  background-size: auto auto;
  transform: scaleX(-1);

  position: absolute;

  z-index: 100;

  padding: 40px 15px 15px 15px;
  box-sizing: border-box;

  top: 160px;
  right: 5%;
`;

const Reverter = styled.div`
  transform: scaleX(-1);
`;

const CheckBox = styled.div<{
  $isOn: boolean;
  $isClickable: boolean;
}>`
  width: 26px;
  height: 26px;

  background: url(${(props) =>
      props.$isClickable
        ? props.$isOn
          ? OnCheckBox
          : OffCheckBox
        : UnClickableCheckbox})
    no-repeat center;
  background-size: contain;

  cursor: ${(props) => (props.$isClickable ? 'pointer' : 'default')};
`;

const RowBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;

  gap: 20px;
`;

const CheckBoxText = styled.div`
  width: fit-content;
  font-family: 'Pretendard';
  font-size: 16px;
  font-weight: 300;
  color: #121212;
`;

const CheckBoxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const WidgetOnOffHandler = observer(({ vm }: { vm: any }) => {
  return (
    <BalloonDiv $isShow={vm.showOnOffHandler}>
      <Reverter>
        <CheckBoxesContainer>
          <RowBox>
            <CheckBox $isOn={true} $isClickable={false} />
            <CheckBoxText>드래프트 보드</CheckBoxText>
          </RowBox>
          <RowBox>
            <CheckBox
              $isOn={vm.WidgetShowState?.chooseChamps}
              $isClickable={true}
              onClick={() => {
                vm.changeWidgetShowState('chooseChamps');
              }}
            />
            <CheckBoxText>챔피언 선택</CheckBoxText>
          </RowBox>
          <RowBox>
            <CheckBox
              $isOn={vm.WidgetShowState?.winrate}
              $isClickable={true}
              onClick={() => {
                vm.changeWidgetShowState('winrate');
              }}
            />
            <CheckBoxText>예상 승률</CheckBoxText>
          </RowBox>
          <RowBox>
            <CheckBox
              $isOn={vm.WidgetShowState?.recommendChamps}
              $isClickable={true}
              onClick={() => {
                vm.changeWidgetShowState('recommendChamps');
              }}
            />
            <CheckBoxText>챔피언 추천</CheckBoxText>
          </RowBox>
          <RowBox>
            <CheckBox
              $isOn={vm.WidgetShowState?.simulate}
              $isClickable={true}
              onClick={() => {
                vm.changeWidgetShowState('simulate');
              }}
            />
            <CheckBoxText>시뮬레이션</CheckBoxText>
          </RowBox>
          <RowBox>
            <CheckBox
              $isOn={vm.WidgetShowState?.globalban}
              $isClickable={true}
              onClick={() => {
                vm.changeWidgetShowState('globalban');
              }}
            />
            <CheckBoxText>글로벌 밴</CheckBoxText>
          </RowBox>
          <RowBox>
            <CheckBox
              $isOn={vm.WidgetShowState?.help}
              $isClickable={true}
              onClick={() => {
                vm.changeWidgetShowState('help');
              }}
            />
            <CheckBoxText>도움말</CheckBoxText>
          </RowBox>
        </CheckBoxesContainer>
      </Reverter>
    </BalloonDiv>
  );
});

export default WidgetOnOffHandler;
