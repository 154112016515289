import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Champ } from '../../type';
import { a } from '@react-spring/web';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5vw;
  align-items: left;
  width: 100%;
`;

const BigBanItemImage = styled.div<{ team: string; isChampion: boolean }>`
  width: 100%;
  ${({ isChampion }) =>
    !isChampion ? 'padding-bottom: 100%;' : 'padding-bottom: 0%;'}
  border: 1px dashed #ccc;
  box-sizing: border-box;
  background: ${({ team }) =>
    team === 'red' ? 'rgba(255, 121, 121, 1)' : 'rgba(121, 223, 255, 1)'};
`;

const BigBanItemContainer = styled.div<{
  $isHighlighted: boolean;
  $isPickEnded?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  max-width: calc(20%);

  @keyframes glowing {
    0% {
      box-shadow: 0 0 10px 0px #00de9b;
    }

    50% {
      box-shadow: 0 0 20px 10px #00de9b;
    }

    100% {
      box-shadow: 0 0 10px 0px #00de9b;
    }
  }
  animation: ${({ $isHighlighted }) =>
    $isHighlighted ? 'glowing 1s infinite' : 'none'};

  cursor: ${(props) =>
    props.$isPickEnded !== undefined && props.$isPickEnded === true
      ? 'grab'
      : 'pointer'};
`;

const SmallBanItemContainer = styled.div<{ $isHighlighted: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  max-width: calc(20%);

  @keyframes glowing {
    0% {
      box-shadow: 0 0 10px 0px #00de9b;
    }

    50% {
      box-shadow: 0 0 20px 10px #00de9b;
    }

    100% {
      box-shadow: 0 0 10px 0px #00de9b;
    }
  }

  animation: ${({ $isHighlighted }) =>
    $isHighlighted ? 'glowing 1s infinite' : 'none'};

  cursor: pointer;
`;
const SamllBanItemImage = styled.div<{ team: string; isChampion: boolean }>`
  width: 100%;
  ${({ isChampion }) =>
    !isChampion ? 'padding-bottom: 100%;' : 'padding-bottom: 0%;'}
  border: 1px dashed #ccc;
  box-sizing: border-box;
  background-color: ${({ team }) =>
    team === 'red' ? 'rgba(255, 121, 121, 1)' : 'rgba(121, 223, 255, 1)'};
  background-image: url('/crossLine.png');
  background-size: cover;
  background-position: center; /* 배경 이미지를 가운데로 설정 */
  background-repeat: no-repeat; /* 배경 이미지 반복을 제거 */
`;
interface Props {
  vm: any;
}
const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const ItemContainer2 = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const BigBanItem = observer(
  ({
    team,
    index,
    isHighlighted,
    selectedChamp,
    confirmedChamp,
    furtherChamp,
    onClick,
    champ,
    isLoading,
  }: {
    team: string;
    index: number;
    isHighlighted?: boolean;
    selectedChamp?: string | null;
    confirmedChamp?: string | null;
    furtherChamp?: string | null;
    onClick?: () => void;
    champ?: Champ | null;
    isLoading?: boolean;
  }) => {
    return (
      <BigBanItemContainer
        draggable={!!champ}
        $isHighlighted={isHighlighted ? isHighlighted : false}
        onClick={isLoading ? () => {} : onClick}
      >
        <div
          style={{
            display: champ?.isSimulated === true ? 'flex' : 'none',
            position: 'absolute',
            fontSize: '1.5rem',
            fontWeight: 900,
            color: 'red',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            textAlign: 'right',
            zIndex: 3,
          }}
        >
          *
        </div>
        <BigBanItemImage
          team={team}
          isChampion={confirmedChamp || furtherChamp ? true : false}
        >
          {confirmedChamp ? (
            <img src={confirmedChamp} alt={confirmedChamp} width={'100%'} />
          ) : furtherChamp ? (
            <img
              style={{
                opacity: 0.5,
              }}
              src={furtherChamp}
              alt={furtherChamp}
              width={'100%'}
            />
          ) : (
            <></>
          )}
        </BigBanItemImage>
      </BigBanItemContainer>
    );
  }
);

const SmallBanItem = ({
  team,
  isHighlighted,
  selectedChamp,
  confirmedChamp,
  furtherChamp,
  onClick,
  champ,
  isLoading,
}: {
  team: string;
  isHighlighted?: boolean;
  selectedChamp?: string | null;
  confirmedChamp?: string | null;
  furtherChamp?: string | null;
  onClick?: () => void;
  champ?: Champ | null;
  isLoading?: boolean;
}) => {
  return (
    <SmallBanItemContainer
      $isHighlighted={isHighlighted ? isHighlighted : false}
      onClick={isLoading ? () => {} : onClick}
    >
      <div
        style={{
          display: champ?.isSimulated === true ? 'flex' : 'none',
          position: 'absolute',
          fontSize: '1.5rem',
          fontWeight: 900,
          color: 'red',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          textAlign: 'right',
          zIndex: 3,
        }}
      >
        *
      </div>
      <SamllBanItemImage
        team={team}
        isChampion={confirmedChamp || furtherChamp ? true : false}
      >
        {confirmedChamp ? (
          <img
            src={confirmedChamp}
            alt={confirmedChamp}
            width={'100%'}
            style={{
              filter: 'grayscale(1)',
            }}
          />
        ) : furtherChamp ? (
          <img
            style={{
              opacity: 0.5,
              filter: 'grayscale(1)',
            }}
            src={furtherChamp}
            alt={furtherChamp}
            width={'100%'}
          />
        ) : (
          <></>
        )}
      </SamllBanItemImage>
    </SmallBanItemContainer>
  );
};

export const BanResultSection = observer(({ vm }: Props) => {
  if (vm.simulationDraftboardState.positionActivated) {
    return (
      <SectionContainer>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <ItemContainer2>
            <SmallBanItem
              team={'blue'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition
                  .bluebantop?.image
              }
              champ={vm.simulationDraftboardState.champTemplateBasic.bluebantop}
            />
            <SmallBanItem
              team={'blue'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition
                  .bluebanjungle?.image
              }
            />
            <SmallBanItem
              team={'blue'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition
                  .bluebanmid?.image
              }
              champ={vm.simulationDraftboardState.champTemplateBasic.bluebantop}
            />
            <SmallBanItem
              team={'blue'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition
                  .bluebanadc?.image
              }
              champ={vm.simulationDraftboardState.champTemplateBasic.bluebantop}
            />
            <SmallBanItem
              team={'blue'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition
                  .bluebansupport?.image
              }
              champ={vm.simulationDraftboardState.champTemplateBasic.bluebantop}
            />
          </ItemContainer2>

          <ItemContainer>
            <BigBanItem
              team={'blue'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition
                  .bluepicktop?.image
              }
              index={0}
              champ={
                vm.simulationDraftboardState.champTemplateBasic.bluepicktop ||
                null
              }
            />
            <BigBanItem
              team={'blue'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition
                  .bluepickjungle?.image
              }
              index={1}
              champ={
                vm.simulationDraftboardState.champTemplateBasic
                  .bluepickjungle || null
              }
            />
            <BigBanItem
              team={'blue'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition
                  .bluepickmid?.image
              }
              index={2}
              champ={
                vm.simulationDraftboardState.champTemplateBasic.bluepickmid ||
                null
              }
            />
            <BigBanItem
              team={'blue'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition
                  .bluepickadc?.image
              }
              index={3}
              champ={
                vm.simulationDraftboardState.champTemplateBasic.bluepickadc ||
                null
              }
            />
            <BigBanItem
              team={'blue'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition
                  .bluepicksupport?.image
              }
              index={4}
              champ={
                vm.simulationDraftboardState.champTemplateBasic
                  .bluepicksupport || null
              }
            />
          </ItemContainer>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <ItemContainer2>
            <SmallBanItem
              team={'red'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition.redbantop
                  ?.image
              }
              champ={vm.simulationDraftboardState.champTemplateBasic.bluebantop}
            />
            <SmallBanItem
              team={'red'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition
                  .redbanjungle?.image
              }
              champ={vm.simulationDraftboardState.champTemplateBasic.bluebantop}
            />
            <SmallBanItem
              team={'red'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition.redbanmid
                  ?.image
              }
              champ={vm.simulationDraftboardState.champTemplateBasic.bluebantop}
            />
            <SmallBanItem
              team={'red'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition.redbanadc
                  ?.image
              }
              champ={vm.simulationDraftboardState.champTemplateBasic.bluebantop}
            />
            <SmallBanItem
              team={'red'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition
                  .redbansupport?.image
              }
              champ={vm.simulationDraftboardState.champTemplateBasic.bluebantop}
            />
          </ItemContainer2>
          <ItemContainer>
            <BigBanItem
              team={'red'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition
                  .redpicktop?.image
              }
              index={5}
              champ={
                vm.simulationDraftboardState.champTemplateBasic.redpicktop ||
                null
              }
            />
            <BigBanItem
              team={'red'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition
                  .redpickjungle?.image
              }
              index={6}
              champ={
                vm.simulationDraftboardState.champTemplateBasic.redpickjungle ||
                null
              }
            />
            <BigBanItem
              team={'red'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition
                  .redpickmid?.image
              }
              index={7}
              champ={
                vm.simulationDraftboardState.champTemplateBasic.redpickmid ||
                null
              }
            />
            <BigBanItem
              team={'red'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition
                  .redpickadc?.image
              }
              index={8}
              champ={
                vm.simulationDraftboardState.champTemplateBasic.redpickadc ||
                null
              }
            />
            <BigBanItem
              team={'red'}
              isHighlighted={undefined}
              confirmedChamp={
                vm.simulationDraftboardState.champTemplateWithPosition
                  .redpicksupport?.image
              }
              index={9}
              champ={
                vm.simulationDraftboardState.champTemplateBasic
                  .redpicksupport || null
              }
            />
          </ItemContainer>
        </div>
      </SectionContainer>
    );
  }

  return (
    <SectionContainer>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <ItemContainer2>
          <SmallBanItem
            isLoading={vm.isSimulationLoading}
            team={'blue'}
            isHighlighted={vm.simulateSelectedChampPosition === 0}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.bluebantop?.image
            }
            onClick={() => vm.setSimulateSelectedChampPosition(0)}
            champ={vm.simulationDraftboardState.champTemplateBasic.bluebantop}
          />
          <SmallBanItem
            isLoading={vm.isSimulationLoading}
            team={'blue'}
            isHighlighted={vm.simulateSelectedChampPosition === 1}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.bluebanjungle
                ?.image
            }
            onClick={() => vm.setSimulateSelectedChampPosition(1)}
            champ={
              vm.simulationDraftboardState.champTemplateBasic.bluebanjungle
            }
          />
          <SmallBanItem
            isLoading={vm.isSimulationLoading}
            team={'blue'}
            isHighlighted={vm.simulateSelectedChampPosition === 2}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.bluebanmid?.image
            }
            onClick={() => vm.setSimulateSelectedChampPosition(2)}
            champ={vm.simulationDraftboardState.champTemplateBasic.bluebanmid}
          />
          <SmallBanItem
            isLoading={vm.isSimulationLoading}
            team={'blue'}
            isHighlighted={vm.simulateSelectedChampPosition === 3}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.bluebanadc?.image
            }
            onClick={() => vm.setSimulateSelectedChampPosition(3)}
            champ={vm.simulationDraftboardState.champTemplateBasic.bluebanadc}
          />
          <SmallBanItem
            isLoading={vm.isSimulationLoading}
            team={'blue'}
            isHighlighted={vm.simulateSelectedChampPosition === 4}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.bluebansupport
                ?.image
            }
            onClick={() => vm.setSimulateSelectedChampPosition(4)}
            champ={
              vm.simulationDraftboardState.champTemplateBasic.bluebansupport
            }
          />
        </ItemContainer2>

        <ItemContainer>
          <BigBanItem
            isLoading={vm.isSimulationLoading}
            team={'blue'}
            isHighlighted={vm.simulateSelectedChampPosition === 10}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.bluepicktop?.image
            }
            index={0}
            champ={
              vm.simulationDraftboardState.champTemplateBasic.bluepicktop ||
              null
            }
            onClick={() => vm.setSimulateSelectedChampPosition(10)}
          />
          <BigBanItem
            isLoading={vm.isSimulationLoading}
            team={'blue'}
            isHighlighted={vm.simulateSelectedChampPosition === 11}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.bluepickjungle
                ?.image
            }
            index={1}
            champ={
              vm.simulationDraftboardState.champTemplateBasic.bluepickjungle ||
              null
            }
            onClick={() => vm.setSimulateSelectedChampPosition(11)}
          />
          <BigBanItem
            isLoading={vm.isSimulationLoading}
            team={'blue'}
            isHighlighted={vm.simulateSelectedChampPosition === 12}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.bluepickmid?.image
            }
            index={2}
            champ={
              vm.simulationDraftboardState.champTemplateBasic.bluepickmid ||
              null
            }
            onClick={() => vm.setSimulateSelectedChampPosition(12)}
          />
          <BigBanItem
            isLoading={vm.isSimulationLoading}
            team={'blue'}
            isHighlighted={vm.simulateSelectedChampPosition === 13}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.bluepickadc?.image
            }
            index={3}
            champ={
              vm.simulationDraftboardState.champTemplateBasic.bluepickadc ||
              null
            }
            onClick={() => vm.setSimulateSelectedChampPosition(13)}
          />
          <BigBanItem
            isLoading={vm.isSimulationLoading}
            team={'blue'}
            isHighlighted={vm.simulateSelectedChampPosition === 14}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.bluepicksupport
                ?.image
            }
            index={4}
            champ={
              vm.simulationDraftboardState.champTemplateBasic.bluepicksupport ||
              null
            }
            onClick={() => vm.setSimulateSelectedChampPosition(14)}
          />
        </ItemContainer>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <ItemContainer2>
          <SmallBanItem
            isLoading={vm.isSimulationLoading}
            team={'red'}
            isHighlighted={vm.simulateSelectedChampPosition === 5}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.redbantop?.image
            }
            onClick={() => vm.setSimulateSelectedChampPosition(5)}
            champ={vm.simulationDraftboardState.champTemplateBasic.redbantop}
          />
          <SmallBanItem
            isLoading={vm.isSimulationLoading}
            team={'red'}
            isHighlighted={vm.simulateSelectedChampPosition === 6}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.redbanjungle
                ?.image
            }
            onClick={() => vm.setSimulateSelectedChampPosition(6)}
            champ={vm.simulationDraftboardState.champTemplateBasic.redbanjungle}
          />
          <SmallBanItem
            isLoading={vm.isSimulationLoading}
            team={'red'}
            isHighlighted={vm.simulateSelectedChampPosition === 7}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.redbanmid?.image
            }
            onClick={() => vm.setSimulateSelectedChampPosition(7)}
            champ={vm.simulationDraftboardState.champTemplateBasic.redbanmid}
          />
          <SmallBanItem
            isLoading={vm.isSimulationLoading}
            team={'red'}
            isHighlighted={vm.simulateSelectedChampPosition === 8}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.redbanadc?.image
            }
            onClick={() => vm.setSimulateSelectedChampPosition(8)}
            champ={vm.simulationDraftboardState.champTemplateBasic.redbanadc}
          />
          <SmallBanItem
            isLoading={vm.isSimulationLoading}
            team={'red'}
            isHighlighted={vm.simulateSelectedChampPosition === 9}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.redbansupport
                ?.image
            }
            onClick={() => vm.setSimulateSelectedChampPosition(9)}
            champ={
              vm.simulationDraftboardState.champTemplateBasic.redbansupport
            }
          />
        </ItemContainer2>
        <ItemContainer>
          <BigBanItem
            isLoading={vm.isSimulationLoading}
            team={'red'}
            isHighlighted={vm.simulateSelectedChampPosition === 15}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.redpicktop?.image
            }
            index={5}
            champ={
              vm.simulationDraftboardState.champTemplateBasic.redpicktop || null
            }
            onClick={() => vm.setSimulateSelectedChampPosition(15)}
          />
          <BigBanItem
            isLoading={vm.isSimulationLoading}
            team={'red'}
            isHighlighted={vm.simulateSelectedChampPosition === 16}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.redpickjungle
                ?.image
            }
            index={6}
            champ={
              vm.simulationDraftboardState.champTemplateBasic.redpickjungle ||
              null
            }
            onClick={() => vm.setSimulateSelectedChampPosition(16)}
          />
          <BigBanItem
            isLoading={vm.isSimulationLoading}
            team={'red'}
            isHighlighted={vm.simulateSelectedChampPosition === 17}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.redpickmid?.image
            }
            index={7}
            champ={
              vm.simulationDraftboardState.champTemplateBasic.redpickmid || null
            }
            onClick={() => vm.setSimulateSelectedChampPosition(17)}
          />
          <BigBanItem
            isLoading={vm.isSimulationLoading}
            team={'red'}
            isHighlighted={vm.simulateSelectedChampPosition === 18}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.redpickadc?.image
            }
            index={8}
            champ={
              vm.simulationDraftboardState.champTemplateBasic.redpickadc || null
            }
            onClick={() => vm.setSimulateSelectedChampPosition(18)}
          />
          <BigBanItem
            isLoading={vm.isSimulationLoading}
            team={'red'}
            isHighlighted={vm.simulateSelectedChampPosition === 19}
            confirmedChamp={
              vm.simulationDraftboardState.champTemplateBasic.redpicksupport
                ?.image
            }
            index={9}
            champ={
              vm.simulationDraftboardState.champTemplateBasic.redpicksupport ||
              null
            }
            onClick={() => vm.setSimulateSelectedChampPosition(19)}
          />
        </ItemContainer>
      </div>
    </SectionContainer>
  );
});

export default BanResultSection;
