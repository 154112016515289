import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../../../hooks/context/AuthContext'; // 필요한 함수와 상태를 가져오기 위해 추가
import { ProfileArea } from '../ProfileArea';
import { TitleWithGoBack } from '../Title';

const ResetPasswordBox = styled.div`
  background: white;
  padding: 60px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; /* 추가 */
`;

const Message = styled.p`
  margin-bottom: 1rem;
  font-size: 18px;
  width: 100%;
  text-align: left;
  color: #333;
`;

const CodeInputContainer = styled.div`
  display: flex;

  justify-content: space-between;
  gap: 8px;
  margin: 1rem 0;
`;

const CodeInput = styled.input`
  width: 20%;
  aspect-ratio: 0.8;
  padding: 1rem;
  background: #f7f7f7;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  text-align: center;
  font-size: 1.5rem;
`;

const ResendLink = styled.a`
  display: flex;
  margin-top: 1rem;
  font-size: 20px;
  width: 100%;
  color: #0057ff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ResetPassword1 = () => {
  const { verifyCode, setVerifyCode, setStep, changePasswordLink } = useAuth();
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleCodeChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newCode = verifyCode.split('');
      newCode[index] = event.target.value;
      setVerifyCode(newCode.join(''));

      // 포커스를 다음 인풋으로 이동
      if (index === inputRefs.current.length - 1) {
        setStep(6);
      }
      if (
        event.target.value.length === event.target.maxLength &&
        index < inputRefs.current.length - 1
      ) {
        inputRefs.current[index + 1]?.focus();
      }
    };

  const handleKeyDown =
    (index: number) => (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        event.key === 'Backspace' &&
        !event.currentTarget.value &&
        index > 0
      ) {
        inputRefs.current[index - 1]?.focus();
      }
    };

  return (
    <ResetPasswordBox>
      <TitleWithGoBack title="비밀번호 변경" />
      <ProfileArea />
      <Message>
        이메일로 비밀번호 변경 링크를 보내드렸습니다.
        <br />
        이메일에서 진행해주세요.
      </Message>
    </ResetPasswordBox>
  );
};

export default ResetPassword1;
