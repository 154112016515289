import styled from "styled-components";
import { NavbarMenu } from "./NavbarMenu";
import { SidebarMenu } from "./SidebarMenu";

interface MenuAreaProps {
    title: string;
    selectedItem: string;
    initialOpen?: boolean;
    fullScreen?: boolean;
    children: React.ReactNode;
    background?:string;
}

const Container = styled.div<{ fullScreen?: boolean }>`
  display: flex;
  height: ${({ fullScreen }) => (fullScreen ? "100vh" : "auto")};
  flex-direction: column;
  font-family: 'Pretendard';
  font-style: normal;
`;

const MainContent = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

export const MenuArea = ({ title, selectedItem, initialOpen, children, fullScreen = true, background}: MenuAreaProps) => {
  return (
    <Container fullScreen={fullScreen}>
      <NavbarMenu title={title} />
      <MainContent style={{background:background}}>
        <SidebarMenu selectedItem={selectedItem} initialOpen={initialOpen} />
          {children}
      </MainContent>
    </Container>
  );
};
