import React from 'react';
import styled from 'styled-components';
import WidgetBox from '../component/stage2/WidgetContainer';
import BanResultSection from './components/BoardSection';
import PositionsIcon from '../../../../assets/positions.svg';
import Arrow from '../../../../assets/positionImplyArrow.svg';
import PosToggleSwitch from './components/toggleSwitch';
import { observer } from 'mobx-react';
import { Champ } from '../type';
import { LoadingSpinner } from '../component/stage2/loadingSpinner';

const PositionInfoBoard = styled.div`
  @media (max-width: 1400px) {
    display: none;
  }

  width: 379px;
  height: 214px;

  background: #ffffff;
  border-radius: 8px;

  font-family: Pretendard;
  font-style: normal;
  font-size: 12px;
  font-weight: 300;

  white-space: pre-wrap;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 20px;
  box-sizing: border-box;
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 35px;
  align-items: flex-start;
  justify-content: center;
`;

const ChampionImage = styled.img`
  width: 40px;
  height: 40px;
`;

const PositionBar = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  width: 229px;
  padding: 8px 0px;
  box-sizing: border-box;
`;

const PositionImplyBar = styled.div`
  width: 229px;
  position: relative;
`;

const PositionImplier = styled.div<{
  top: number;
  jungle: number;
  mid: number;
  adc: number;
  support: number;
}>`
  width: 229px;
  height: 32px;

  margin-top: 10px;

  background: linear-gradient(
    90deg,
    ${({ top }) => `rgba(126, 64, 255, ${(top * 2) / 100})`} 5%,
    ${({ jungle }) => `rgba(126, 64, 255, ${(jungle * 2) / 100})`} 28%,
    ${({ mid }) => `rgba(126, 64, 255, ${(mid * 2) / 100})`} 50%,
    ${({ adc }) => `rgba(126, 64, 255, ${(adc * 2) / 100})`} 73%,
    ${({ support }) => `rgba(126, 64, 255, ${(support * 2) / 100})`} 95%
  );
`;

const ImplyLabel = ({ max, left }: { max: number; left: number }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',

        justifyContent: 'center',
        alignItems: 'center',

        position: 'absolute',
        left: `${left}%`,
      }}
    >
      <img src={Arrow} alt="arrow" width={8} />
      <div
        style={{
          fontFamily: 'Beaufort for LOL',
          fontStyle: 'italic',
          fontWeight: 900,
          fontSize: '16px',

          textAlign: 'center',
          color: '#242424',
        }}
      >
        {max.toFixed(1)}%
      </div>
    </div>
  );
};

const Context = styled.div`
  font-family: Pretendard;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;

  color: #242424;
  white-space: pre-wrap;

  text-align: left;
`;

const PositionInfo = ({
  data,
  isLoading,
}: {
  data: {
    champ: Champ;
    top: number;
    jungle: number;
    mid: number;
    adc: number;
    support: number;
  } | null;
  isLoading?: boolean;
}) => {
  const max = Math.max(
    data?.top || 0,
    data?.jungle || 0,
    data?.mid || 0,
    data?.adc || 0,
    data?.support || 0
  );
  const maxPosition = Object.keys(data || {}).find(
    (key) => data && data[key as keyof typeof data] === max
  );

  const translateV = {
    top: -5,
    jungle: 20,
    mid: 43,
    adc: 66,
    support: 90,
  };

  const korPosition = {
    top: '탑',
    jungle: '정글',
    mid: '미드',
    adc: '원딜',
    support: '서폿',
  };

  if (isLoading === true) {
    return <LoadingSpinner width={30} />;
  } else if (data?.champ === null) {
    return <div>{'챔피언을 눌러\n포지션 정보를 확인해보세요.'}</div>;
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <RowFlex>
        <ChampionImage src={data?.champ?.image} />
        <PositionBar>
          <img src={PositionsIcon} alt="positions" />
          <PositionImplier
            top={data!.top}
            jungle={data!.jungle}
            mid={data!.mid}
            adc={data!.adc}
            support={data!.support}
          />
          <PositionImplyBar>
            <ImplyLabel
              max={max}
              left={translateV[maxPosition as keyof typeof translateV]}
            />
          </PositionImplyBar>
        </PositionBar>
      </RowFlex>
      <Context>
        {`${data?.champ?.korName} 픽은 ${
          korPosition[maxPosition as keyof typeof translateV]
        }에 배정될 확률이 높습니다.`}
      </Context>
    </div>
  );
};

const DraftboardWidget = observer(({ vm }: any) => {
  return (
    <WidgetBox vm={vm} type="draftboard">
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          height: '100%',
          padding: '20px',
          gap: '5%',
        }}
      >
        <BanResultSection width="40%" vm={vm} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            top: '-5%',

            gap: '10px',
          }}
        >
          <PosToggleSwitch vm={vm} />
          <PositionInfoBoard>
            {vm.isPickCompleted ? (
              <div
                style={{
                  fontSize: '16px',
                }}
              >
                {
                  '픽이 완료되었습니다.\n\n원하는 포지션이 되도록 스왑을 진행하거나\n포지션 반영해서 보기 버튼을 누른 후\n드래프트 완료하기 버튼을 눌러주세요.'
                }
              </div>
            ) : (
              <PositionInfo
                data={
                  vm.draftboardState?.positionInfo[
                    vm.draftboardState?.chosenChampionPos
                  ]
                }
                isLoading={vm.loadingState.draftboard}
              />
            )}
          </PositionInfoBoard>
        </div>
      </div>
    </WidgetBox>
  );
});

export default DraftboardWidget;
