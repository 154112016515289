import axios from 'axios';
import { developStatus } from '../configs/developStatus';
import Cookies from 'js-cookie';
import { RefreshRequest, RefreshResponse } from './types/authApiTypes';

let baseURL: string | undefined = '';

if (developStatus === 'develop') {
  console.log('개발 환경입니다.');
  baseURL = process.env.REACT_APP_DEV_BACKEND_URL;
} else if (developStatus === 'test') {
  console.log('테스트 환경입니다.');
  baseURL = process.env.REACT_APP_TEST_BACKEND_URL;
} else if (developStatus === 'production') {
  baseURL = process.env.REACT_APP_PROD_BACKEND_URL;
}
const apiClient = axios.create({
  baseURL: baseURL, // 백엔드 API URL로 변경하세요
  headers: {
    'Content-Type': 'application/json',
  },
});

// 로컬 스토리지에서 토큰을 가져오는 함수
const getToken = () => {
  return localStorage.getItem('accessToken');
};

// 토큰을 로컬 스토리지에 저장하는 함수
const setToken = (token: string) => {
  localStorage.setItem('accessToken', token);
};
apiClient.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 419 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = Cookies.get('refreshToken');

      await axios
        .post<RefreshResponse>(`${baseURL}/auth/refresh`, {
          refreshToken,
        })
        .then((response) => {
          const accessToken = response.data.accessToken;
          const refreshToken = response.data.refreshToken;
          setToken(accessToken);
          Cookies.set('refreshToken', refreshToken);
          axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${accessToken}`;
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return apiClient(originalRequest);
        })
        .catch((refreshError) => {
          console.error('Refresh token expired or invalid.');
          localStorage.removeItem('accessToken');
          Cookies.remove('refreshToken');
          window.location.href = '/auth';
          return Promise.reject(refreshError);
        });
    }
    return Promise.reject(error);
  }
);

export default apiClient;
