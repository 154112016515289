import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';
import { RoasterData, RoasterPlayer } from '../../pages/userdata/roaster/type';
import * as roasterApis from '../../apis/userDataApis';
import { usePlayer } from './PlayerContext';

const positions = ['top', 'jungle', 'mid', 'adc', 'support'];

// Define the types for the context data
interface HighlightedPosition {
  roasterIndex: number;
  position: string;
}
interface RoasterContextType {
  isAllRoasterLoading: boolean;
  setIsAllRoasterLoading: (isLoading: boolean) => void;
  isRoasterLoading: boolean[];
  setIsRoasterLoading: (isLoading: boolean[]) => void;
  roasterDataList: RoasterData[];
  setRoasterDataList: (roasters: RoasterData[]) => void;
  nowRoasterPosition: HighlightedPosition | null;
  setNowRoasterPosition: (position: HighlightedPosition | null) => void;
  roasterChange: (player: RoasterPlayer) => void;
  addRoaster: () => void;
  removeRoaster: (index: number) => void;
  noti: {
    status: string;
    message: string;
    isVisible: boolean;
    isAnimating: boolean;
  };
  setNoti: (noti: {
    status: string;
    message: string;
    isVisible: boolean;
    isAnimating: boolean;
  }) => void;
  changeOnlyName: (changePlayer: RoasterPlayer) => void;
}

// Create the context
const RoasterContext = createContext<RoasterContextType | undefined>(undefined);

interface Props {
  children: ReactNode;
}

// Create the provider component
export const RoasterProvider: React.FC<Props> = ({ children }) => {
  const { setPrioritizedPosition } = usePlayer();
  const [roasterDataList, setRoasterDataListState] = useState<RoasterData[]>(
    []
  );
  const [isAllRoasterLoading, setIsAllRoasterLoading] =
    useState<boolean>(false);
  const { fetchPlayerList } = usePlayer();
  const [playerAllDataList, setPlayerAllDataList] = useState<RoasterPlayer[]>(
    []
  );
  const [isRoasterLoading, setIsRoasterLoading] = useState<boolean[]>([]);

  const [nowRoasterPosition, setNowRoasterPosition] =
    useState<HighlightedPosition | null>(null);

  const setRoasterDataList = useCallback(
    (roasters: RoasterData[]) => setRoasterDataListState(roasters),
    []
  );
  const [noti, setNoti] = useState<{
    status: string;
    message: string;
    isVisible: boolean;
    isAnimating: boolean;
  }>({
    status: '',
    message: '',
    isVisible: false,
    isAnimating: false,
  });

  const setHighlightedPosition = (position: HighlightedPosition | null) =>
    setNowRoasterPosition(position);

  const fetchPlayerBase: () => Promise<RoasterPlayer[]> =
    useCallback(async () => {
      const result = await roasterApis.getPlayerBase().then((response) => {
        if (response.code === 200) {
          const newPlayerList = response.players.map((player) => {
            return {
              id: player.id,
              name: player.name,
              isbase: true,
              is_db: true,
              is_syncronized: true,
              position: player.position,
              image: player.image,
              league: player.league,
            };
          });
          return newPlayerList;
        } else {
          return [];
        }
      });
      return result;
    }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (noti.isVisible) {
      timeout = setTimeout(() => {
        setNoti({ ...noti, isVisible: false });
      }, 800);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [noti]);

  const getAllPlayerDataAndSum = useCallback(async () => {
    const result = await Promise.all([fetchPlayerList(), fetchPlayerBase()]);
    setPlayerAllDataList([...result[0], ...result[1]]);
  }, [fetchPlayerBase, fetchPlayerList]);

  const getRoasterFetch = useCallback(async () => {
    const playerCache: { [key: number]: RoasterPlayer } = {};

    await roasterApis.getRoaster().then(async (response) => {
      if (response.code === 200) {
        const roasterDataResponse = response.roasters;

        const roasterDataList = await Promise.all(
          roasterDataResponse!.map(async (roaster) => {
            const isbase = roaster.user_id === null;

            const playerList = await Promise.all(
              [
                roaster.top_player,
                roaster.jungle_player,
                roaster.mid_player,
                roaster.adc_player,
                roaster.support_player,
              ].map(async (playerId) => {
                if (playerCache[playerId]) {
                  // 캐시된 데이터가 있으면 API 호출 없이 캐시된 데이터 반환
                  return playerCache[playerId];
                }

                const playerData = playerAllDataList.find(
                  (player) => player.id === playerId
                );

                if (playerData) {
                  const player = {
                    id: playerData.id,
                    name: playerData.name,
                    isbase: playerData.isbase,
                    is_db: true,
                    is_syncronized: true,
                    position: playerData.position,
                    image: playerData.image,
                    league: playerData.league,
                  };
                  playerCache[playerId] = player; // 캐시에 저장
                  return player;
                } else {
                }

                return {
                  id: 0,
                  name: '',
                  isbase: false,
                  is_db: false,
                  is_syncronized: false,
                  position: '',
                  image: '',
                  league: '',
                };
              })
            );

            return {
              id: roaster.id,
              name: roaster.name,
              playerList,
              isbase: isbase,
            };
          })
        );

        setRoasterDataList(roasterDataList);
        setIsRoasterLoading(new Array(roasterDataList.length).fill(false));
        setIsAllRoasterLoading(false);
      }
    });
  }, [playerAllDataList, setRoasterDataList]);

  useEffect(() => {
    setIsAllRoasterLoading(true);
    const initializeData = async () => {
      await getAllPlayerDataAndSum();
    };

    initializeData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (playerAllDataList.length > 0) {
      getRoasterFetch();
    }
  }, [playerAllDataList]); // eslint-disable-line react-hooks/exhaustive-deps

  const roasterChange = async (changePlayer: RoasterPlayer) => {
    const newLoadingList = [...isRoasterLoading];
    if (nowRoasterPosition === null) {
      return;
    }
    for (let i = 0; i < roasterDataList.length; i++) {
      if (nowRoasterPosition?.roasterIndex === i) {
        newLoadingList[i] = true;
      }
    }
    setIsRoasterLoading(newLoadingList);

    {
      const newPlayerList = roasterDataList[
        nowRoasterPosition?.roasterIndex
      ].playerList.map((player, index) => {
        if (positions[index] === nowRoasterPosition?.position) {
          return {
            id: changePlayer.id,
            name: changePlayer.name,
            isbase: changePlayer.isbase,
            is_db: changePlayer.is_db,
            is_syncronized: changePlayer.is_syncronized,
            position: changePlayer.position,
            image: changePlayer.image,
            league: changePlayer.league,
          };
        }
        return player;
      });

      const newRoasterDataList = roasterDataList.map((roaster, index) => {
        if (index === nowRoasterPosition?.roasterIndex) {
          return {
            id: roaster.id,
            name: roaster.name,
            playerList: newPlayerList,
            isbase: roaster.isbase,
          };
        }
        return roaster;
      });

      await roasterApis
        .editRoaster({
          id: roasterDataList[nowRoasterPosition.roasterIndex].id,
          name: roasterDataList[nowRoasterPosition.roasterIndex].name,
          changePosition: nowRoasterPosition.position,
          player: changePlayer.id,
        })
        .then((response) => {
          if (response.code === 200) {
            setRoasterDataList(newRoasterDataList);
          }
        })
        .catch((error) => {
          setNoti({
            status: 'error',
            message: '로스터 변경에 실패했습니다.',
            isVisible: true,
            isAnimating: true,
          });
        })
        .finally(() => {
          setIsRoasterLoading(new Array(roasterDataList.length).fill(false));
        });
    }
  };

  const changeOnlyName = async (changePlayer: RoasterPlayer) => {
    await roasterApis
      .editRoaster({
        id: roasterDataList[nowRoasterPosition!.roasterIndex].id,
        name: roasterDataList[nowRoasterPosition!.roasterIndex].name,
        changePosition:
          nowRoasterPosition!.position === ''
            ? 'top'
            : nowRoasterPosition!.position,
        player: changePlayer.id === 0 ? null : changePlayer.id,
      })
      .catch((error) => {
        setNoti({
          status: 'error',
          message: '로스터 변경에 실패했습니다.',
          isVisible: true,
          isAnimating: true,
        });
      });
  };

  const addRoaster = async () => {
    const beforeLoading = [...isRoasterLoading];
    setIsRoasterLoading([...beforeLoading, true]);

    const newData = {
      id: 0,
      name: '',
      playerList: [
        {
          id: 0,
          name: '',
          isbase: false,
          is_db: false,
          is_syncronized: false,
          position: 'top',
          image: '',
          league: '',
        },
        {
          id: 0,
          name: '',
          isbase: false,
          is_db: false,
          is_syncronized: false,
          position: 'jungle',
          image: '',
          league: '',
        },
        {
          id: 0,
          name: '',
          isbase: false,
          is_db: false,
          is_syncronized: false,
          position: 'mid',
          image: '',
          league: '',
        },
        {
          id: 0,
          name: '',
          isbase: false,
          is_db: false,
          is_syncronized: false,
          position: 'adc',
          image: '',
          league: '',
        },
        {
          id: 0,
          name: '',
          isbase: false,
          is_db: false,
          is_syncronized: false,
          position: 'support',
          image: '',
          league: '',
        },
      ],
      isbase: false,
    };
    const updateBeforeValue = [...roasterDataList];

    setRoasterDataList([...updateBeforeValue, { ...newData }]);

    await roasterApis
      .putRoaster({
        name: newData.name,
        top: newData.playerList[0].id === 0 ? null : newData.playerList[0].id,
        jg: newData.playerList[1].id === 0 ? null : newData.playerList[1].id,
        mid: newData.playerList[2].id === 0 ? null : newData.playerList[2].id,
        adc: newData.playerList[3].id === 0 ? null : newData.playerList[3].id,
        spt: newData.playerList[4].id === 0 ? null : newData.playerList[4].id,
      })
      .then((response) => {
        if (response.code === 200) {
          const roasterId = response!.roasters![0].id;

          setRoasterDataList([
            ...updateBeforeValue,
            { ...newData, id: roasterId },
          ]);
        }
      })
      .catch((error) => {
        setNoti({
          status: 'error',
          message: '로스터 변경에 실패했습니다.',
          isVisible: true,
          isAnimating: true,
        });
      })
      .finally(() => {
        setIsRoasterLoading([...beforeLoading, false]);
        setNowRoasterPosition(null);
      });
  };

  const removeRoaster = async (index: number) => {
    const newRoasterDataList = roasterDataList.filter((_, i) => i !== index);

    await roasterApis
      .dropRoaster({ id: roasterDataList[index].id })
      .then((response) => {
        if (response.code === 200) {
          setRoasterDataList(newRoasterDataList);
        }
      })
      .catch((error) => {
        setNoti({
          status: 'error',
          message: '로스터 변경에 실패했습니다.',
          isVisible: true,
          isAnimating: true,
        });
      })
      .finally(() => {
        setNowRoasterPosition(null);
      });
  };

  return (
    <RoasterContext.Provider
      value={{
        isAllRoasterLoading,
        setIsAllRoasterLoading,
        isRoasterLoading,
        setIsRoasterLoading,
        roasterDataList,
        setRoasterDataList,
        nowRoasterPosition,
        setNowRoasterPosition,
        roasterChange,
        addRoaster,
        removeRoaster,
        noti,
        setNoti,
        changeOnlyName,
      }}
    >
      {children}
    </RoasterContext.Provider>
  );
};

// Create a custom hook to use the RoasterContext
export const useRoaster = () => {
  const context = useContext(RoasterContext);
  if (!context) {
    throw new Error('useRoaster must be used within a RoasterProvider');
  }
  return context;
};
