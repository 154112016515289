import { RoasterProvider } from '../../../hooks/context/RoasterContext';
import { MenuArea } from '../../../components/mainMenu/MenuArea';
import RoasterArea from './RoasterArea';
import { PlayerProvider } from '../../../hooks/context/PlayerContext';

const UserDataRoasterContent = () => {
  return (
    <MenuArea
      title="로스터 관리"
      selectedItem="로스터 등록"
      background="#F6F6F6"
    >
      <RoasterArea />
    </MenuArea>
  );
};

const UserDataRoaster = () => {
  return (
    <PlayerProvider>
      <RoasterProvider>
        <UserDataRoasterContent />
      </RoasterProvider>
    </PlayerProvider>
  );
};

export default UserDataRoaster;
