import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from 'react';
import * as userDataApis from '../../apis/userDataApis';
import * as authApis from '../../apis/authApis';

interface UserData {
  email: string;
  phoneNumber: string;
  userName: string;
  profileImage: string; // 이미지 상태 추가
}

interface UserDataContextType {
  status: string;
  notiText: string;
  isDisplay: boolean;
  userData: UserData;
  setUserData: React.Dispatch<React.SetStateAction<UserData>>;
  getUserData: () => Promise<void>;
  clickEditSubmit: () => Promise<void>;
  clickImgSubmit: (imageFile: File) => Promise<void>;
}

const UserDataContext = createContext<UserDataContextType | undefined>(
  undefined
);

interface Props {
  children: ReactNode;
}

export const UserDataProvider: React.FC<Props> = ({ children }) => {
  const [userData, setUserData] = useState<UserData>({
    email: '',
    phoneNumber: '',
    userName: '',
    profileImage: '/pProfile.png', // 기본 이미지 경로 설정
  });
  const [status, setStatus] = useState<string>('');
  const [notiText, setNotiText] = useState<string>('');
  const [isDisplay, setIsDisplay] = useState<boolean>(false);

  const setNoti = (status: string, text: string, display: boolean) => {
    setStatus(status);
    setNotiText(text);
    setIsDisplay(display);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isDisplay) {
      timeout = setTimeout(() => {
        setIsDisplay(false);
      }, 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isDisplay]);

  const getUserData = useCallback(async () => {
    setNoti('loading', '', true);
    await userDataApis
      .getUserData()
      .then((response) => {
        if (response.code === 200) {
          setUserData({
            email: response.data.email,
            phoneNumber: response.data.call,
            userName: response.data.name,
            profileImage: response.data.image,
          });
        }
        setNoti('success', response.message, true);
      })
      .catch((err) => {
        console.error(err);
        setNoti('error', err.response.data.message, true);
      });
  }, []);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const clickEditSubmit = async () => {
    setNoti('loading', '', true);
    await authApis
      .changeUserInfo({
        emailChange: userData.email,
        call: userData.phoneNumber,
        name: userData.userName,
      })
      .then((response) => {
        if (response.code === 200) {
          setUserData((prevData) => ({
            ...prevData,
            email: userData.email,
            phoneNumber: userData.phoneNumber,
            userName: userData.userName,
          }));
          setNoti('success', response.message, true);
          getUserData();
        }
      })
      .catch((err) => {
        console.error(err);
        setNoti('error', err.response.data.message, true);
      });
  };

  const clickImgSubmit = async (imageFile: File) => {
    setNoti('loading', '', true);
    await authApis
      .uploadUserImage(imageFile)
      .then((response) => {
        if (response.code === 200) {
          setUserData((prevData) => ({
            ...prevData,
            profileImage: response.data.imageUrl,
          }));
          setNoti('success', response.message, true);
        }
      })
      .catch((err) => {
        console.error(err);
        setNoti('error', err.response.data.message, true);
      });
  };

  return (
    <UserDataContext.Provider
      value={{
        status,
        notiText,
        isDisplay,
        userData,
        setUserData,
        getUserData,
        clickEditSubmit,
        clickImgSubmit,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => {
  const context = useContext(UserDataContext);
  if (!context) {
    throw new Error('useUserData must be used within a UserDataProvider');
  }
  return context;
};
