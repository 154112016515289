// ScreenWidthContext.tsx
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';

interface ScreenWidthContextProps {
  isScreenTooSmall: boolean;
}

const ScreenWidthContext = createContext<ScreenWidthContextProps | undefined>(
  undefined
);

export const ScreenWidthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isScreenTooSmall, setIsScreenTooSmall] = useState(
    window.innerWidth <= 1024
  );

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsScreenTooSmall(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', checkScreenWidth);
    return () => window.removeEventListener('resize', checkScreenWidth);
  }, []);

  return (
    <ScreenWidthContext.Provider value={{ isScreenTooSmall }}>
      {children}
    </ScreenWidthContext.Provider>
  );
};

export const useScreenWidth = (): ScreenWidthContextProps => {
  const context = useContext(ScreenWidthContext);
  if (!context) {
    throw new Error('useScreenWidth must be used within a ScreenWidthProvider');
  }
  return context;
};
