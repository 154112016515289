import React, { useEffect } from 'react';
import styled from 'styled-components';
import { LoadingSpinner } from '../stage2/loadingSpinner';

const ToggleButton = styled.select`
  width: 100px;
  height: 40px;
  background-color: #f0f0f0;
  border: 1px solid #222;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
`;

const ToggleButtonDiv = styled.div`
  width: 100px;
  height: 40px;
  background-color: #f0f0f0;
  border: 1px solid #222;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
`;

export const PatchSelectToggle = ({
  setPatch,
  patchLists,
  setPatchLists,
}: {
  setPatch: (patch: string) => void;
  patchLists: string[] | null;
  setPatchLists: any;
}) => {
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPatch(event.target.value);
  };

  useEffect(() => {
    setPatchLists();
  }, [setPatchLists]);

  if (patchLists === null) {
    return (
      <ToggleButtonDiv>
        <LoadingSpinner width={20} />
      </ToggleButtonDiv>
    );
  }

  if (patchLists.length === 0) {
    return (
      <ToggleButton onChange={handleSelectChange}>
        <option value="0.0">latest</option>
        <option value="14.23">14.23</option>
        <option value="14.22">14.22</option>
        <option value="14.21">14.21</option>
        <option value="14.20">14.20</option>
        <option value="14.19">14.19</option>
        <option value="14.18">14.18</option>
        <option value="14.17">14.17</option>
        <option value="14.16">14.16</option>
        <option value="14.15">14.15</option>
        <option value="14.14">14.14</option>
        <option value="14.13">14.13</option>
        <option value="14.12">14.12</option>
        <option value="14.11">14.11</option>
        <option value="14.10">14.10</option>
        <option value="14.9">14.9</option>
        <option value="14.8">14.8</option>
        <option value="14.7">14.7</option>
        <option value="14.6">14.6</option>
        <option value="14.5">14.5</option>
        <option value="14.4">14.4</option>
        <option value="14.3">14.3</option>
        <option value="14.2">14.2</option>
        <option value="14.1">14.1</option>
        <option value="13.23">13.23</option>
        <option value="13.22">13.22</option>
        <option value="13.21">13.21</option>
        <option value="13.20">13.20</option>
        <option value="13.19">13.19</option>
        <option value="13.18">13.18</option>
        <option value="13.17">13.17</option>
        <option value="13.16">13.16</option>
        <option value="13.15">13.15</option>
        <option value="13.14">13.14</option>
        <option value="13.13">13.13</option>
        <option value="13.12">13.12</option>
        <option value="13.11">13.11</option>
        <option value="13.10">13.10</option>
        <option value="13.9">13.9</option>
        <option value="13.8">13.8</option>
        <option value="13.7">13.7</option>
        <option value="13.6">13.6</option>
      </ToggleButton>
    );
  }

  return (
    <ToggleButton onChange={handleSelectChange}>
      <option value="0.0">latest</option>
      {patchLists.map((patch) => (
        <option key={patch} value={patch}>
          {patch}
        </option>
      ))}
    </ToggleButton>
  );
};
