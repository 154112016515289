import { useEffect, useRef, useState } from 'react';

const useSectionHeight = () => {
  const minHeight = 200;
  const [sectionHeight, setSectionHeight] = useState(0); // 초기 높이 설정
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setSectionHeight(Math.max(entry.contentRect.height, minHeight));
      }
    });

    const currentSection = sectionRef.current;
    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);

  return { sectionRef, sectionHeight };
};

export default useSectionHeight;
