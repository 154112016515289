import {
  PutHistoryRequest,
  PutHistoryResponse,
  GetHistoryRequest,
  GetHistoryResponse,
} from './types/historyApiTypes';
import apiClient from './apiClient';

// 히스토리 관련 API
export const putHistory = async (
  data: PutHistoryRequest
): Promise<PutHistoryResponse> => {
  const response = await apiClient.post('/history/put', data);
  return response.data;
};

export const changeHistory = async (
  data: PutHistoryRequest
): Promise<PutHistoryResponse> => {
  const response = await apiClient.post('/history/change', data);
  return response.data;
};

export const getHistory = async (
  params: GetHistoryRequest
): Promise<GetHistoryResponse> => {
  const response = await apiClient.get('/history/get', { params });
  return response.data;
};
