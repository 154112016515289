import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import WidgetBox from '../component/stage2/WidgetContainer';
import RecommendCard from '../component/stage2/RecommendCard';
import { Champ, RecommendedChamp } from '../type';
import { observer } from 'mobx-react';
import { KpSetter } from '../component/stage2/ParameterSetter';
import { toJS } from 'mobx';
import { LoadingSpinner } from '../component/stage2/loadingSpinner';

const VerticalScrollBox = styled.div`
  display: flex;
  width: 650px;
  height: 200px;

  margin-top: -2.5%;

  flex-direction: row;
  gap: 10px;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #7e40ff50;
    border-radius: 2px;
  }
`;

const ChooseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ChooseButton = styled.div<{ $isActivated: boolean }>`
  width: 160px;
  height: 38px;

  background: ${(props) =>
    props.$isActivated ? 'rgba(126, 64, 255, 0.25)' : 'rgba(126, 64, 255, 1)'};
  border-radius: 12px;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 900;
  font-size: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${(props) => (props.$isActivated ? '#727272' : '#fafafa')};

  cursor: ${(props) => (props.$isActivated ? 'default' : 'pointer')};
`;

const ScrollableContainer = ({ children }: { children: React.ReactNode }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleWheel = (e: WheelEvent) => {
    if (!scrollRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;

    // 스크롤 가능 여부 판단
    const isAtStart = scrollLeft === 0;
    const isAtEnd = scrollLeft + clientWidth >= scrollWidth;

    // 스크롤 가능한 경우 이벤트 차단 및 가로 스크롤 처리
    if (!(isAtStart && e.deltaY < 0) && !(isAtEnd && e.deltaY > 0)) {
      e.preventDefault(); // 기본 동작 차단
      scrollRef.current.scrollLeft += e.deltaY; // 가로 스크롤 이동
    }
  };

  useEffect(() => {
    const currentRef = scrollRef.current;

    if (currentRef) {
      currentRef.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  return <VerticalScrollBox ref={scrollRef}>{children}</VerticalScrollBox>;
};

const RecommendChampWidget = observer(({ vm }: any) => {
  const [showParameterSetter, setShowParameterSetter] = useState(false);

  // 파라미터 아이콘 클릭 시 호출될 함수
  const handleParameterIconClick = () => {
    setShowParameterSetter(!showParameterSetter);
  };

  if (vm.loadingState.recommend === true) {
    return (
      <WidgetBox vm={vm} type="recommendChamps">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <LoadingSpinner width={60} />
        </div>
      </WidgetBox>
    );
  }

  return (
    <WidgetBox
      vm={vm}
      type="recommendChamps"
      // canModify={true}
      // onParameterClick={handleParameterIconClick}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          padding: '20px',
          boxSizing: 'border-box',
          gap: '10px',
        }}
      >
        {/* ParameterSetter를 조건부로 렌더링 */}
        {showParameterSetter && (
          <KpSetter vm={vm} onClose={() => setShowParameterSetter(false)} />
        )}
        <ScrollableContainer>
          {vm.recommendChampsState.recommendChamps.map(
            (recommendChamp: RecommendedChamp, index: number) => {
              const isNon = (
                Object.values(vm.draftboardState.champTemplateBasic) as Champ[]
              )
                .concat(vm.globalBanState.globalBan as Champ[])
                .some((champ: Champ) => champ?.index === recommendChamp?.index);

              return (
                <RecommendCard
                  vm={vm}
                  recommendChamp={recommendChamp}
                  isHighlighted={
                    vm.parentState?.selectedChamp?.index ===
                    recommendChamp.index
                  }
                  key={index}
                  onClickF={vm.setSelectedChamp}
                  isNonClickable={isNon}
                />
              );
            }
          )}
        </ScrollableContainer>
        <ChooseContainer>
          <ChooseButton
            $isActivated={
              vm.parentState?.selectedChamp?.korName !== undefined
                ? false
                : true
            }
            onClick={() =>
              vm.parentState?.selectedChamp?.korName && vm.nextTurn()
            }
          >
            {vm.parentState?.selectedChamp?.korName || ''} 선택하기
          </ChooseButton>
        </ChooseContainer>
      </div>
    </WidgetBox>
  );
});

export default RecommendChampWidget;
