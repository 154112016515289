import React from 'react';
import styled from 'styled-components';
import { Champ } from '../../type';
import BanChamp from '../../../../../assets/bannedChamp.svg';
import { toJS } from 'mobx';

const BannedChampionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;

  cursor: pointer;
`;

const ChampionImageContainer = styled.div`
  width: 40px;
  height: 40px;

  border-radius: 50%;

  position: relative;
`;

const ChampionImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const BanChampImage = styled.img`
  position: absolute;
  width: 40px;
  height: 40px;
`;

const SubText = styled.div`
  color: #fafafa;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Pretendard';
`;

const BannedChampion = ({ champ, onClick }: { champ: Champ; onClick: any }) => {
  return (
    <BannedChampionContainer
      onClick={() => {
        onClick(champ);
      }}
    >
      <ChampionImageContainer>
        <ChampionImage src={champ.image} />
        <BanChampImage src={BanChamp} />
      </ChampionImageContainer>
      <SubText>{champ.korName}</SubText>
    </BannedChampionContainer>
  );
};

export default BannedChampion;
