import React, { useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../../../hooks/context/AuthContext';
import { ContinueButton } from '../../../../components/common/button/ContinueButton';
import { Input, Label } from '../../../../components/common/input/Input';
import { TitleWithGoBack } from '../Title';
import { PasswordInput } from '../../../../components/common/input/PasswordInput';
import EmailInput from '../../../../components/common/input/EmailInput';
import PhoneNumberInput from '../../../../components/common/input/PhoneNumberInput';
import Notification from '../../../../components/common/Notification';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const SignUpSidebar = styled.div`
  background: white;
  width: 500px;
  border-radius: 8px 0px 0px 8px;
  height: 100vh;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
  box-sizing: border-box;
  padding-bottom: 4rem;
  text-align: left;
`;

const Content = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
`;

const SignUp = () => {
  const {
    setStep,
    notiText,
    notiStatus,
    notiDisplay,
    signUpEmail,
    setSignUpEmail,
    signUpName,
    setSignUpName,
    signUpPhoneNumber,
    setSignUpPhoneNumber,
    signUpPassword,
    setSignUpPassword,
    signUpConfirmPassword,
    setSignUpConfirmPassword,
    register,
  } = useAuth();

  const handleContinue = () => {
    register();
  };

  return (
    <Container>
      <Notification
        text={notiText}
        status={notiStatus}
        isDisplay={notiDisplay}
      />
      <SignUpSidebar>
        <TitleWithGoBack title="회원가입" />
        <Content>
          <div>
            <Label htmlFor="email">이메일</Label>
            <EmailInput
              value={signUpEmail}
              onChange={(e) => setSignUpEmail(e.target.value)}
            />

            <Label htmlFor="team">구단명</Label>
            <Input
              id="team"
              type="text"
              value={signUpName}
              onChange={(e) => setSignUpName(e.target.value)}
            />

            <PhoneNumberInput
              label="대표 전화번호"
              value={signUpPhoneNumber}
              onChange={setSignUpPhoneNumber}
            />
            <Label htmlFor="password">비밀번호</Label>
            <PasswordInput
              onChange={(e) => setSignUpPassword(e.target.value)}
              value={signUpPassword}
            />
            <Label htmlFor="confirmPassword">비밀번호 확인</Label>
            <PasswordInput
              onChange={(e) => setSignUpConfirmPassword(e.target.value)}
              value={signUpConfirmPassword}
            />
            <ContinueButton onClick={handleContinue} title="계속하기" />
          </div>
        </Content>
      </SignUpSidebar>
    </Container>
  );
};

export default SignUp;
