import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Input } from '../../../../components/common/input/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';

interface EditableInputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const NameInput = styled(Input)<{ disabled: boolean }>`
  background: transparent;
  width: 280px;
  text-align: left;
  padding: 0;
  font-weight: 600;
  font-size: 48px;
  color: #121212;
  border: none;
  border-bottom: 1px solid #ddd;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const EditIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: black;
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 10px;
`;

const EditableInput = ({ value, onChange }: EditableInputProps) => {
  const [isEditingName, setIsEditingName] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditingName) {
      inputRef.current?.focus();
    }
  }, [isEditingName]);

  const handleBlur = () => {
    setIsEditingName(false);
  };

  return (
    <div>
      <NameInput
        value={value}
        onChange={onChange}
        disabled={!isEditingName}
        ref={inputRef}
        onBlur={handleBlur}
      />
      <EditIcon icon={faPen} onClick={() => setIsEditingName(true)} />
    </div>
  );
};

export default EditableInput;
