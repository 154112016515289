import styled from 'styled-components';
import TeamPlayerSection from './TeamPlayerSection';
import { observer } from 'mobx-react';

const TopBorderDiv = styled.div`
  width: 100%;
  background: linear-gradient(90deg, #001aff 25.5%, #ff0000 75%);
  height: 5px;
`;

const BottomBorderDiv = styled.div`
  width: 100%;
  height: 5px;
  background: rgba(0, 0, 0, 0.1);
  border: 0.5px solid #8d8d8d;
  border-radius: 2.5px;
`;

const TeamContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface PlayerSectionProps {
  MiddleMenu: JSX.Element;
  blueTeamPlayers: any;
  redTeamPlayers: any;
  editable?: boolean;
  vm: any;
}
const PlayerSection = observer(
  ({
    MiddleMenu,
    blueTeamPlayers,
    redTeamPlayers,
    vm,
    editable = true,
  }: PlayerSectionProps) => {
    return (
      <>
        <TopBorderDiv />
        <TeamContainer>
          <TeamPlayerSection
            team="blue"
            players={blueTeamPlayers}
            editable={editable}
            vm={vm}
          />
          {MiddleMenu}
          <TeamPlayerSection
            team="red"
            players={redTeamPlayers}
            editable={editable}
            vm={vm}
          />
        </TeamContainer>
        <BottomBorderDiv />
      </>
    );
  }
);

export default PlayerSection;
