import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ImageWithName } from '../common/icon/ProfileIcon'; // Import your ImageWithName component
import { Input } from '../common/input/Input';
import { PlayerData, RoasterPlayer } from '../../pages/userdata/roaster/type';
import { PlayerProvider, usePlayer } from '../../hooks/context/PlayerContext';
import Spinner from '../common/loading';

const PlayerSearchContainer = styled.div<{ height: string }>`
  height: ${(props) => props.height};
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 0px 20px;
  background: #282828;
  box-sizing: border-box;
`;

const SearchBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;

const SearchButton = styled.div`
  padding: 10px;
  font-size: 28px;
  color: white;
  background-color: transparent;
  border: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
`;

const PlayerGrid = styled.div`
  width: 90%;
  align-self: center;
  display: grid;

  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
  grid-auto-rows: 90px;
  gap: 15px;

  color: white;
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: center;
  align-items: grid-start;

  /* Scrollbar styles */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
    border: 2px solid transparent;
    background-clip: padding-box;
    height: 20%;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #ccc;
  }

  @media (max-width: 1200px) {
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(55px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(65px, 1fr));
    gap: 5px;
  }
`;

const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 62px;
  margin-right: 10px;
`;

const CustomInput = styled(Input)`
  background: transparent;
  font-size: 1.3rem;
  padding: 5px;
  color: white;
  width: 350px;
  border-bottom: 1px solid white;
`;

const CustomButton = styled.button`
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 20px;
  padding: 10px 20px;
  margin-left: auto; /* 자동 여백으로 오른쪽 끝으로 배치 */
  border: none;
  cursor: pointer;
`;

interface SearchPlayerProps {
  onPlayerClick: (player: RoasterPlayer, index: number) => void;
  buttonName?: string;
  containerHeight: string; // new prop for height
  isLoading?: boolean;
  filteredPlayers: RoasterPlayer[];
}

export const SearchPlayer = ({
  onPlayerClick,
  buttonName,
  containerHeight,
  isLoading,
  filteredPlayers,
}: SearchPlayerProps) => {
  const { index, searchTerm, setSearchTermInside, handleAddPlayer } =
    usePlayer();
  const playerGridRef = useRef<HTMLDivElement>(null);

  const handleAddPlayerAndScroll = () => {
    handleAddPlayer();
    if (playerGridRef.current) {
      // 스크롤을 가장 아래로 이동
      playerGridRef.current.scrollTop = playerGridRef.current.scrollHeight;
    }
  };

  return (
    <PlayerSearchContainer height={containerHeight}>
      <SearchBar>
        <SearchInputWrapper>
          <SearchButton>
            <FontAwesomeIcon icon={faSearch} />
          </SearchButton>
          <CustomInput
            placeholder="여러 키워드로 검색... (LCK, t1, mid...)"
            value={searchTerm}
            onChange={(e) => setSearchTermInside(e.target.value)}
          />
        </SearchInputWrapper>
        {buttonName && (
          <CustomButton onClick={handleAddPlayerAndScroll}>
            {buttonName}
          </CustomButton>
        )}
      </SearchBar>
      {isLoading ? (
        <Spinner />
      ) : (
        <PlayerGrid ref={playerGridRef}>
          {filteredPlayers.map((data, pindex) => (
            <ImageWithName
              key={data.id}
              image={data.image}
              name={data.name}
              radius={60}
              onClick={() => onPlayerClick(data, pindex)}
              highlighted={!data.is_db}
              isGlowing={index === pindex}
            />
          ))}
        </PlayerGrid>
      )}
    </PlayerSearchContainer>
  );
};
