import styled from 'styled-components';
import { Player } from '../../../pages/userdata/history/type';
import { observer } from 'mobx-react';
import { RoasterPlayer } from '../../../pages/userdata/roaster/type';

const PlayerCard = styled.div<{ radius: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: ${({ radius }) => `${radius}px`};
`;

const PlayerImage = styled.div<{
  highlighted: boolean;
  radius: number;
  backgroundColor?: string;
  url?: string;
  isPlayer: boolean;
  team?: string;
}>`
  width: 70%;
  padding-bottom: 70%; /* Maintain square aspect ratio */
  border-radius: 50%;
  border: 1px solid white;
  box-sizing: border-box;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? `rgba(${backgroundColor}, 0.4)` : 'white'};
  background-image: url(${({ url }) => url});
  background-size: ${({ isPlayer }) => (isPlayer ? '100%' : '70%')};
  background-position: center;
  background-repeat: no-repeat;
  /* glow effect */

  @keyframes glowing {
    0% {
      box-shadow: 0 0 5px 0px rgba(46, 46, 46, 0.75);
    }
    50% {
      box-shadow: 0 0 10px 6px rgba(46, 46, 46, 0.75);
    }
    100% {
      box-shadow: 0 0 5px 0px rgba(46, 46, 46, 0.75);
    }
  }

  animation: ${(props) =>
    props.highlighted ? 'glowing 1000ms infinite' : 'none'};

  cursor: pointer;
`;

const PlayerName = styled.div`
  font-family: 'Pretendard';
  font-size: 1rem;
  font-weight: 200;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Adjust font size if text is too long */
  @media (max-width: 300px) {
    font-size: 0.8rem;
  }
  @media (max-width: 200px) {
    font-size: 0.6rem;
  }
`;

interface Props {
  player: RoasterPlayer;
  team: string;
  radius: number;
  highlighted: boolean;
  name: string;
  position?: string;
  onClick: () => void;
  style?: React.CSSProperties;
}

export const CircleImageWithName = observer(
  ({
    player,
    team,
    highlighted,
    onClick,
    radius,
    position,
    name,
    style,
  }: Props) => {
    const image = player.image ? player.image : '/pProfile.png';
    const url = player.name ? image : `/${position}.png`;
    const isPlayer = player.name ? true : false;
    const background = player.name
      ? 'transparent'
      : team === 'red'
      ? '255, 121, 121'
      : '121, 223, 255';

    return (
      <PlayerCard onClick={onClick} radius={radius}>
        <PlayerImage
          radius={radius}
          backgroundColor={background}
          url={url}
          isPlayer={isPlayer}
          highlighted={highlighted}
          team={team}
        />
        <PlayerName style={{ ...style }}>{name}</PlayerName>
      </PlayerCard>
    );
  }
);
