import React, { useState } from 'react';
import styled from 'styled-components';
import { RecommendedChamp } from '../../type';
import PositionHeatMap from './PositionHeatmap';
import MyosuIcon from '../../../../../assets/myosu.svg';
import BestPickIcon from '../../../../../assets/bestpick.svg';
import Balloon from '../../../../../assets/wordBalloon.svg';
import { Champ } from '../../type';

const ChampImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const BalloonDiv = styled.div<{ position: { top: number; left: number } }>`
  width: 140px;
  height: 92px;
  background: url(${Balloon}) no-repeat center;
  background-size: contain;
  position: fixed; /* 화면 좌표에 고정 */
  top: ${({ position }) => position.top - 12}px;
  left: ${({ position }) => position.left + 35}px;
  z-index: 100;
  pointer-events: none;
  padding: 10px 15px;
  box-sizing: border-box;
`;

const IconTitle = styled.div`
  font-family: 'Pretendard';
  font-size: 12px;
  color: #242424;
  font-weight: 700;
  text-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.2);
  margin-left: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const IconContext = styled.div`
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;

  color: #000000;

  white-space: pre-wrap;
`;

const HoverBalloon = ({
  type,
  position,
}: {
  type: string;
  position: { top: number; left: number };
}) => {
  return (
    <BalloonDiv position={position}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {type === 'bestpick' ? (
            <>
              <img
                src={BestPickIcon}
                style={{
                  width: '20px',
                  height: '20px',
                  objectFit: 'fill',
                  borderRadius: '50%',
                  boxShadow: '0px 0px 6px 2px rgba(0,0,0, 0.2)',
                }}
                alt="bestpick"
              />
              <IconTitle>최적의 픽</IconTitle>
            </>
          ) : (
            <>
              <img
                src={MyosuIcon}
                style={{
                  width: '20px',
                  height: '20px',
                  objectFit: 'fill',
                  borderRadius: '50%',
                  boxShadow: '0px 0px 6px 2px rgba(0,0,0, 0.2)',
                }}
                alt="myosu"
              />
              <IconTitle>묘수</IconTitle>
            </>
          )}
        </div>
        <IconContext>
          {type === 'bestpick'
            ? 'GLPT가 제안하는\n가장 좋은 픽입니다.'
            : '다소 변칙적이지만,\n승률을 높이는 픽입니다.'}
        </IconContext>
      </div>
    </BalloonDiv>
  );
};

const RecommendChampContainer = styled.div<{
  $isHighlighted?: boolean;
}>`
  width: 140px;
  height: 180px;
  display: flex;
  flex-direction: column;
  background: rgba(217, 217, 217, 0.01);
  box-shadow: ${(props) =>
    props.$isHighlighted
      ? '0px 0px 10px 2px rgba(200, 200, 100, 0.5)'
      : '0px 0px 10px 2px rgba(128, 128, 128, 0.4)'};
  font-family: 'Pretendard';
  justify-content: center;
  box-sizing: border-box;
  margin: 10px 0px;
  padding: 10px;
  gap: 14px;
  position: relative;

  cursor: pointer;
`;

const RateTitle = styled.div`
  font-size: 10px;
  color: #fafafa;
  margin-bottom: 5px;
  font-weight: 200;
`;

const Rate = styled.div`
  font-size: 16px;
  color: #fafafa;
  margin-bottom: 10px;
  font-weight: 600;
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PositionPredictTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #fafafa;
  margin-bottom: 5px;
  font-weight: 200;
`;

const RecommendCard = ({
  vm,
  recommendChamp,
  isHighlighted,
  onClickF,
  isNonClickable,
}: {
  vm: any;
  recommendChamp: RecommendedChamp;
  isHighlighted?: boolean;
  onClickF: (champ: Champ) => void;
  isNonClickable?: boolean;
}) => {
  const keyTeamDict = {
    1: 'blue',
    2: 'red',
    3: 'blue',
    4: 'red',
    5: 'blue',
    6: 'red',
    7: 'blue',
    8: 'red',
    9: 'red',
    10: 'blue',
    11: 'blue',
    12: 'red',
    13: 'red',
    14: 'blue',
    15: 'red',
    16: 'blue',
    17: 'red',
    18: 'blue',
    19: 'blue',
    20: 'red',
  };

  const [isHovering, setIsHovering] = useState(false);
  const [hoverType, setHoverType] = useState<string | null>(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (type: string, event: React.MouseEvent) => {
    const targetRect = (event.target as HTMLElement).getBoundingClientRect();
    setPosition({
      top: targetRect.top - 80, // 아이콘 위에 배치
      left: targetRect.left + targetRect.width / 2 - 55, // 중앙 정렬
    });
    setIsHovering(true);
    setHoverType(type);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    setHoverType(null);
  };

  const thWord =
    recommendChamp.order % 10 === 1
      ? 'st'
      : recommendChamp.order % 10 === 2
      ? 'nd'
      : recommendChamp.order % 10 === 3
      ? 'rd'
      : 'th';

  return (
    <RecommendChampContainer
      $isHighlighted={isHighlighted}
      onClick={() => {
        !isNonClickable && onClickF(recommendChamp as Champ);
      }}
    >
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: isNonClickable ? 'block' : 'none',
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: '5px',
          gap: '10px',
          height: '40px',
        }}
      >
        <ChampImage src={recommendChamp.image} />{' '}
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          {recommendChamp.isBest ? (
            <img
              src={BestPickIcon}
              style={{ width: '20px', height: '20px', objectFit: 'fill' }}
              alt="bestpick"
              onMouseEnter={(event) => handleMouseEnter('bestpick', event)}
              onMouseLeave={handleMouseLeave}
            />
          ) : (
            recommendChamp.isMyosu && (
              <img
                src={MyosuIcon}
                style={{ width: '20px', height: '20px', objectFit: 'fill' }}
                alt="myosu"
                onMouseEnter={(event) => handleMouseEnter('myosu', event)}
                onMouseLeave={handleMouseLeave}
              />
            )
          )}

          {/* Balloon이 아이콘에 hover되었을 때만 표시 */}
          {isHovering && hoverType && (
            <HoverBalloon type={hoverType} position={position} />
          )}
        </div>
      </div>

      <RowFlex>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <RateTitle>승률</RateTitle>
          <Rate>{recommendChamp.winrate}%</Rate>
        </div>
        <span style={{ width: '10px' }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <RateTitle>픽률</RateTitle>
          <Rate>
            {recommendChamp.order}
            {thWord}
          </Rate>
        </div>
      </RowFlex>
    </RecommendChampContainer>
  );
};

export default RecommendCard;
