import RecommendArea from './RecommendArea';
import { useLocation } from 'react-router-dom';
import RecommendVM from './vm';
import { observer } from 'mobx-react';

const MainFuncView = observer(() => {
  const location = useLocation();
  const vm = new RecommendVM();

  return <RecommendArea key={location.pathname} vm={vm} />;
});

export default MainFuncView;
