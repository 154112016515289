// SimulateWidget.tsx
import React from 'react';
import WidgetBox from '../component/stage2/WidgetContainer';
import { observer } from 'mobx-react';
import BanResultSection from './components/SimulateBoardSection';
import SimulateChooseChampion from './components/SimulationChooseSection';
import styled from 'styled-components';
import SimulateTeamPlayerSection from './components/SimulateTeamPlayerSection';
import {
  SimulatePosToggleSwitch,
  SimulateSearchSwitch,
} from './components/SimulateToggle';
import { useEffect } from 'react';
import { PlayerProvider } from '../../../../hooks/context/PlayerContext';
import { LoadingSpinner } from '../component/stage2/loadingSpinner';

interface SimulateWidgetProps {
  vm: any;
  gridColumn?: string;
  onClick?: () => void;
}

const StyledButton = styled.div<{ $active?: boolean }>`
  width: 107px;
  height: 38px;

  background: ${(props) =>
    props.$active ? '#7e40ff' : 'rgba(126, 64, 255, 0.5)'};
  box-shadow: ${(props) =>
    props.$active ? '0px 0px 4px 2px rgba(126, 64, 255, 0.5)' : 'none'};
  border-radius: 8px;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  text-align: center;

  color: ${(props) => (props.$active ? '#fafafa' : '#727272')};

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:hover {
    background: ${(props) =>
      props.$active ? '#7e40ff' : 'rgba(126, 64, 255, 0.75)'};
    box-shadow: ${(props) =>
      props.$active ? '0px 0px 4px 2px rgba(126, 64, 255, 0.5)' : 'none'};
  }
`;

const SimulateWidget = observer(
  ({ vm, gridColumn, onClick }: SimulateWidgetProps) => {
    useEffect(() => {
      vm.initializeSimulate();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <WidgetBox
        vm={vm}
        type="simulate"
        // canModify={true}
        gridColumn={gridColumn}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              position: 'absolute',
              display: vm.isSimulationLoading ? 'flex' : 'none',
              top: 70,
              left: 70,
            }}
          >
            <LoadingSpinner width={20} />
          </div>
          <div
            style={{
              width: '40%',
            }}
            className="DraftBoard"
          >
            <SimulatePosToggleSwitch vm={vm} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <SimulateTeamPlayerSection
                team="blue"
                players={vm.simulateBluePlayers}
                editable={true}
                vm={vm}
              />
              <span
                style={{
                  display: 'block',
                  width: 100,
                }}
              />
              <SimulateTeamPlayerSection
                team="red"
                players={vm.simulateRedPlayers}
                editable={true}
                vm={vm}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <BanResultSection vm={vm} />
            </div>
          </div>
          <PlayerProvider>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '45%',
                height: '100%',
                flexDirection: 'column',
                padding: '10px 20px',
                boxSizing: 'border-box',
                gap: 10,
              }}
            >
              <SimulateSearchSwitch vm={vm} />
              <SimulateChooseChampion vm={vm} />
            </div>
          </PlayerProvider>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 40,
            }}
          >
            <StyledButton
              $active={!vm.simulationActivated && !vm.isSimulationLoading}
              onClick={
                !vm.isSimulationLoading ? () => vm.startSimulation() : () => {}
              }
            >
              시뮬레이션 시작
            </StyledButton>
            <StyledButton
              $active={!vm.simulationStopActivated}
              onClick={() => vm.stopSimulation()}
            >
              시뮬레이션 중단
            </StyledButton>
            <StyledButton
              $active={!vm.simulationActivated && !vm.isSimulationLoading}
              onClick={
                !vm.isSimulationLoading
                  ? () => vm.initializeSimulate()
                  : () => {}
              }
            >
              현재 시점과 동기화
            </StyledButton>
          </div>
        </div>
      </WidgetBox>
    );
  }
);

export default SimulateWidget;
