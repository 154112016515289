import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: none;
  background: #0057ff;
  border-radius: 16px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    background: #0047d4;
  }

  & > span {
    margin-left: 0.5rem;
  }
`;
interface ContinueButtonProps {
  onClick?: any;
  disabled?: boolean;
  title: string;
}
export const ContinueButton = ({
  onClick,
  disabled,
  title,
}: ContinueButtonProps) => {
  return (
    <Button onClick={onClick} disabled={disabled}>
      {title}{' '}
      <span>
        <FontAwesomeIcon icon={faChevronRight} />
      </span>
    </Button>
  );
};
