import React from 'react';
import WidgetBox from '../component/stage2/WidgetContainer';
import LinePlot from './components/WinrateGraphs';
import { observer } from 'mobx-react';
import { LoadingSpinner } from '../component/stage2/loadingSpinner';
import { toJS } from 'mobx';

const WinrateWidget = observer(({ vm }: any) => {
  const graphData = vm.winrateState;
  const simGraphData = vm.SimulateWinrateState;

  return (
    <WidgetBox vm={vm} type="winrate">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          height: '100%',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '10%',
            right: '10%',
            transform: 'translate(-50%, -50%)',
            display: vm.loadingState.winrate === true ? 'block' : 'none',
          }}
        >
          <LoadingSpinner width={20} />
        </div>
        <LinePlot data={graphData} simData={simGraphData} />
      </div>
    </WidgetBox>
  );
});

export default WinrateWidget;
