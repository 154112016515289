import React from 'react';
import styled from 'styled-components';
import { CircleImageWithName } from '../../../../../components/common/icon/CircleImageWithName';
import { Player } from '../../../../userdata/history/type';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { RoasterPlayer } from '../../../../userdata/roaster/type';

const MiddleContainer = styled.div`
  width: 100%;
  display: flex;

  justify-content: space-between;
  align-items: center;

  gap: 10px;
`;

const CircleName = ({
  player,
  team,
}: {
  player: RoasterPlayer;
  team: string;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '45px',
      }}
    >
      <img
        src={player.image ? player.image : '/pProfile.png'}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          border: `4px solid ${team === 'blue' ? '#2c31ab' : '#b20808'}`,
        }}
        alt="player"
      />
      <div
        style={{
          fontSize: '12px',
          textOverflow: 'ellipsis',
        }}
      >
        {player.name}
      </div>
    </div>
  );
};

interface TeamSectionProps {
  team: 'blue' | 'red';
  players: RoasterPlayer[];
  editable?: boolean;
  vm: any;
}

export const TeamPlayerSection: React.FC<TeamSectionProps> = observer(
  ({ team, players, vm, editable = true }) => {
    const positions = ['top', 'jungle', 'mid', 'adc', 'support'];

    return (
      <MiddleContainer>
        {players.map((player, index) => (
          <CircleName player={player} key={index} team={team} />
        ))}
      </MiddleContainer>
    );
  }
);

export default TeamPlayerSection;
