import React, { useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useUserData } from '../../../hooks/context/UserDataContext';
import EditableInput from './component/EditableInput';
import { Input, Label } from '../../../components/common/input/Input';
import PhoneNumberInput from '../../../components/common/input/PhoneNumberInput';
import { ContinueButton } from '../../../components/common/button/ContinueButton';
import Spinner from '../../../components/common/loading';
import Notification from '../../../components/common/Notification';

const ProfileSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const ProfileImage = styled.img`
  position: relative;
  width: 189px;
  height: 189px;
  border-radius: 50%;
`;

const EditIconWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  transform: translate(25%, 25%);
  border: 1px solid #ddd;
  background: white;
  color: black;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: black;
`;

const Form = styled.form`
  position: relative;
  background: white;
  padding: 20px;
  width: 70%;
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const FileInput = styled.input`
  display: none;
`;

const Link = styled.a`
  display: block;
  font-size: 0.9rem;
  color: #0057ff;
  text-decoration: none;
  font-family: 'Pretendard';
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

const ProfileArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  padding: 30px 30px 0px 0px;
`;

const EmailInput = styled(Input)`
  width: 80%;
`;

const EntireFormGroup = styled.div`
  width: 100%;
  margin-bottom: 3vh;
  flex: 1;
  padding: 30px 0;
`;
const BackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background: url('/authbackground.png') no-repeat center center;
  background-size: cover;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      95deg,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.8)
    );
    z-index: 1;
  }
  & > * {
    position: relative;
    z-index: 2;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  align-items: flex-start;
  gap: 2rem;
  flex: 1;
  overflow: hidden;
  align-self: center;
`;
export const EditProfileArea = () => {
  const {
    status,
    userData,
    notiText,
    isDisplay,
    setUserData,
    clickEditSubmit,
    clickImgSubmit,
  } = useUserData();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      clickImgSubmit(e.target.files[0]);
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserData((prevState) => ({
      ...prevState,
      userName: e.target.value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    clickEditSubmit();
  };

  return (
    <BackgroundContainer>
      <Container>
        <ProfileArea>
          <ProfileSection>
            <ProfileImage
              src={
                userData.profileImage === null
                  ? '/profile.png'
                  : userData.profileImage
              }
              alt="Profile"
            />
            <EditIconWrapper onClick={() => fileInputRef.current?.click()}>
              <EditIcon icon={faPen} />
            </EditIconWrapper>
            <FileInput
              type="file"
              accept="image/*"
              ref={fileInputRef}
              onChange={handleImageChange}
            />
          </ProfileSection>
          <EditableInput
            value={userData.userName}
            onChange={handleNameChange}
          />
        </ProfileArea>
        <Form onSubmit={handleSubmit}>
          <Notification status={status} text={notiText} isDisplay={isDisplay} />
          <EntireFormGroup>
            {status === 'loading' ? (
              <Spinner />
            ) : (
              <>
                <FormGroup>
                  <Label>이메일</Label>
                  <EmailInput
                    type="email"
                    value={userData.email}
                    onChange={(e) =>
                      setUserData({ ...userData, email: e.target.value })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <PhoneNumberInput
                    label="대표전화번호"
                    value={userData.phoneNumber}
                    onChange={(value) =>
                      setUserData({ ...userData, phoneNumber: value })
                    }
                  />
                </FormGroup>
              </>
            )}
          </EntireFormGroup>
          <ContinueButton
            onClick={(e: React.FormEvent) => handleSubmit(e)}
            title="수정하기"
          />
          <Label>
            비밀번호를 변경하고 싶으신가요?{' '}
            <Link href="#">비밀번호 변경하기</Link>
          </Label>
        </Form>
      </Container>
    </BackgroundContainer>
  );
};
