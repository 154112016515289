import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Input, Label } from "./Input";  // Assuming you have these components

interface PhoneNumberInputProps {
    value: string;
    onChange: (value: string) => void;
    label: string;
}

const PhoneNumberContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;

  & > input {
    text-align: left;
  }
`;

const InputShort = styled(Input)`
    margin-bottom: 0;
    &:focus {
    border-bottom: 2px solid #0057ff;
  }
  width: 15%;
`;

const InputLong = styled(Input)`
margin-bottom: 0;
&:focus {
    border-bottom: 2px solid #0057ff;
  }
  width: 20%;
`;

const Dash = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    font-size: 1.5rem;
    font-weight: 100;
`;

const PhoneNumberInput = ({ value, onChange, label }: PhoneNumberInputProps) => {
    const [part1, setPart1] = useState(value.substring(0, 3));
    const [part2, setPart2] = useState(value.substring(3, 7));
    const [part3, setPart3] = useState(value.substring(7, 11));

    const inputRefs = [useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null)];

    const updateValue = (p1: string, p2: string, p3: string) => {
        const newValue = p1 + p2 + p3;
        onChange(newValue);
    };

    const handlePhoneNumberChange = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        if (index === 0) {
            setPart1(newValue);
            updateValue(newValue, part2, part3);
        } else if (index === 1) {
            setPart2(newValue);
            updateValue(part1, newValue, part3);
        } else if (index === 2) {
            setPart3(newValue);
            updateValue(part1, part2, newValue);
        }

        // If the current input value length reaches max length, move focus to the next input
        if (newValue.length === e.target.maxLength && index < inputRefs.length - 1) {
            inputRefs[index + 1].current?.focus();
        }
    };

    const handleKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace' && !e.currentTarget.value && index > 0) {
            inputRefs[index - 1].current?.focus();
        }
    };

    return (
        <>
            <Label>{label}</Label>
            <PhoneNumberContainer>
                <InputShort
                    type="text"
                    maxLength={3}
                    value={part1}
                    onChange={handlePhoneNumberChange(0)}
                    onKeyDown={handleKeyDown(0)}
                    ref={inputRefs[0]}
                />
                <Dash>-</Dash>
                <InputLong
                    type="text"
                    maxLength={4}
                    value={part2}
                    onChange={handlePhoneNumberChange(1)}
                    onKeyDown={handleKeyDown(1)}
                    ref={inputRefs[1]}
                />
                <Dash>-</Dash>
                <InputLong
                    type="text"
                    maxLength={4}
                    value={part3}
                    onChange={handlePhoneNumberChange(2)}
                    onKeyDown={handleKeyDown(2)}
                    ref={inputRefs[2]}
                />
            </PhoneNumberContainer>
        </>
    );
};

export default PhoneNumberInput;
