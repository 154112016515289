import styled from "styled-components";
import { useAuth } from "../../../hooks/context/AuthContext";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Title = styled.div`
  font-weight: 600;
    font-size: 48px;
    letter-spacing: 0.05em;
    color: #121212;
    margin: 0;
`;
const Container = styled.div`
    display:flex;
    flex-direction: row;
    width: 100%;

`;
const Button = styled.button`
  padding: 0.5rem 1rem;
  background: none;
  border: none;
  cursor: pointer;

  & > svg {
    font-size: 1.5rem;
    color: #0057FF;
  }
  &:hover {
    background: #f9f9f9;
  }
`;
export const TitleWithGoBack = ({ title }: { title: string }) => {
    const { goBack } = useAuth();
    return (
        <Container>
            <Button onClick={goBack}><FontAwesomeIcon icon={faChevronLeft} /></Button>
            <Title>{title}</Title>
        </Container>
    )
}