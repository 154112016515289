import styled from 'styled-components';
import MiddleMenu from './MiddleMenu';
import { SearchPlayer } from '../../../../../components/search/SearchPlayer';
import ButtonMenu from './ButtonMenu';
import PlayerSection from './PlayerSection';
import useSectionHeight from '../../../../../hooks/useSectionHeight';
import { observer } from 'mobx-react';
import { usePlayer } from '../../../../../hooks/context/PlayerContext';
import { PatchSelectToggle } from './PatchSelectToggle';
import SelectTeam from './SelectTeam';

const BackgroundDiv = styled.div`
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 1rem 2rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  gap: 1.5rem;

  overflow: hidden;
`;

const TeamsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #c7c7c7;
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 40px 60px;
  box-sizing: border-box;
  @media (max-width: 1048px) and (min-width: 768px) {
    padding: 5px 10px; /* Smaller padding */
  }
`;

const SearchSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  overflow: hidden; /* Prevent overflow on the search section */
`;

const RecommendStage1 = observer(({ vm }: any) => {
  const { filteredPlayers, isLoading } = usePlayer();
  const { sectionRef, sectionHeight } = useSectionHeight(); // 훅을 사용하여 sectionRef와 sectionHeight를 가져옵니다.

  return (
    <BackgroundDiv>
      <Container>
        <PatchSelectToggle
          setPatch={vm.setPatchVersion}
          patchLists={vm.patchLists}
          setPatchLists={vm.getPatchVersion}
        />
        <TeamsContainer>
          <PlayerSection
            MiddleMenu={<MiddleMenu vm={vm} />}
            blueTeamPlayers={vm.blueTeamPlayers}
            redTeamPlayers={vm.redTeamPlayers}
            vm={vm}
          />
        </TeamsContainer>
        <ButtonMenu />
        <SearchSection ref={sectionRef}>
          <SearchPlayer
            onPlayerClick={vm.setPlayer}
            containerHeight={`300px`}
            filteredPlayers={filteredPlayers}
            isLoading={isLoading}
          />
        </SearchSection>
        <SelectTeam vm={vm} />
      </Container>
    </BackgroundDiv>
  );
});

export default RecommendStage1;
