import styled from "styled-components";
export const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  font-family: 'Pretendard';
  font-size: 16px;
  margin-bottom: 1rem;
  border: none; /* 모든 경계선 제거 */
  border-bottom: 2px solid #ddd; /* 하단 경계선만 추가 */
  border-radius: 0; /* 테두리 반경 제거 */
  box-shadow: none; /* 입력 필드의 그림자 제거 */
  box-sizing: border-box; /* 내부 패딩과 테두리를 포함한 전체 너비와 높이를 계산 */
  outline: none; /* 포커스 시 기본 테두리 제거 */
  transition: all 0.3s ease; /* 모든 속성에 대해 트랜지션 추가 */
  
  &:focus {
    border-bottom: 2px solid #0057ff;
    color: #0057ff; /* 예시: 포커스 시 텍스트 색상 변경 */
  }
`;

export const Label = styled.label`
  text-align: left;
  font-size: 16px;
  color: #666;
  display: block;
  line-height: 26px;
`;
