import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const slideDown = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
`;

const LoadingBarContainer = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 5px;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
`;

const moveCircle = keyframes`
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
`;

const LoadingCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 5px;
  height: 5px;
  background-color: black;
  border-radius: 50%;
  transform: translateY(-50%);
  animation: ${moveCircle} 2s infinite linear;
`;

const LoadingCircles = styled.div`
  display: flex;
  gap: 5px;
  position: relative;
  width: 100%;
  height: 5px;
  overflow: hidden;
  & > div {
    animation: ${moveCircle} 2s infinite linear;
  }
  & > div:nth-child(1) {
    animation-delay: 0s;
  }
  & > div:nth-child(2) {
    animation-delay: 0.2s;
  }
  & > div:nth-child(3) {
    animation-delay: 0.4s;
  }
  & > div:nth-child(4) {
    animation-delay: 0.6s;
  }
  & > div:nth-child(5) {
    animation-delay: 0.8s;
  }
`;

interface NotificationStyleProps {
  $status: string;
  $isDisplay: boolean;
}

const NotificationStyle = styled.div<NotificationStyleProps>`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  background: ${(props) => {
    if (props.$status === 'success') return '#20e600';
    else if (props.$status === 'error') return '#ff0000';
    else if (props.$status === 'loading') return 'transparent';
  }};
  border-radius: 8px 8px 0px 0px;
  color: white;
  display: ${(props) => (props.$isDisplay ? 'flex' : 'none')};
  align-items: center;
  text-align: left;
  ${(props) =>
    props.$isDisplay
      ? props.$status === 'loading'
        ? ''
        : css`
            animation: ${slideDown} 0.5s forwards;
          `
      : css`
          animation: ${slideUp} 0.5s forwards;
        `}
`;

const CircleIcon = styled(FontAwesomeIcon)`
  font-size: 10px;
  margin-right: 5px;
`;

interface NotificationProps {
  text: string;
  status: string;
  isDisplay: boolean;
}

const Notification = ({ text, status, isDisplay }: NotificationProps) => {
  return (
    <NotificationStyle $status={status} $isDisplay={isDisplay}>
      <CircleIcon icon={faCircle} />
      {text}
      {status === 'loading' && (
        <LoadingBarContainer>
          <LoadingCircles>
            <LoadingCircle />
            <LoadingCircle />
            <LoadingCircle />
            <LoadingCircle />
            <LoadingCircle />
          </LoadingCircles>
        </LoadingBarContainer>
      )}
    </NotificationStyle>
  );
};

export default Notification;
