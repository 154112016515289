import React from 'react';
import styled from 'styled-components';
import { useAuth } from '../../../../hooks/context/AuthContext';
import { ProfileArea } from '../ProfileArea';
import { ContinueButton } from '../../../../components/common/button/ContinueButton';
import { TitleWithGoBack } from '../Title';
import { PasswordInput } from '../../../../components/common/input/PasswordInput';
import Notification from '../../../../components/common/Notification';

const LoginBox = styled.div`
  background: white;
  padding: 60px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 600px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const SubTitle = styled.p`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 20px;
  color: #666;
`;

const Link = styled.a`
  display: block;
  font-size: 20px;
  color: #0057ff;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const AuthStep2 = () => {
  const {
    setStep,
    setLoginPassword,
    loginPassword,
    clickLoginSubmit,
    notiStatus,
    notiText,
    notiDisplay,
    changePasswordLink,
  } = useAuth();

  const handleContinue = () => {
    clickLoginSubmit();
  };

  const handleFindPassword = () => {
    changePasswordLink();
    setStep(5);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginPassword(event.target.value);
  };
  return (
    <div style={{ position: 'relative' }}>
      <Notification
        text={notiText}
        status={notiStatus}
        isDisplay={notiDisplay}
      />
      <LoginBox>
        <TitleWithGoBack title="비밀번호 입력" />
        <ProfileArea />
        <PasswordInput value={loginPassword} onChange={handlePasswordChange} />
        <ContinueButton onClick={handleContinue} title="계속하기" />
        <SubTitle>
          <div>비밀번호를 잊으셨나요?</div>
          <Link onClick={handleFindPassword}>비밀번호 찾기</Link>
        </SubTitle>
      </LoginBox>
    </div>
  );
};

export default AuthStep2;
