import React, { useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { ImageWithName, PlayerRole } from '../common/icon/ProfileIcon';
import { Input } from '../common/input/Input';
import { useRoaster } from '../../hooks/context/RoasterContext';
import { RoasterPlayer } from '../../pages/userdata/roaster/type';
import Spinner from '../common/loading';

const Roaster = styled.div`
  display: flex;
  color: black;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset 0px 0px 4px 0.5px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background: #f8f8f8;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 5%;
  height: 120px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const RoasterHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const Icon = styled(FontAwesomeIcon)`
  cursor: pointer;
  font-size: 36px;
  &:hover {
    color: #666;
  }
`;

const RoasterInput = styled(Input)<{ editable?: boolean }>`
  background: transparent;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 0;
  width: 180px;
  margin-bottom: 0px;
  ${({ editable }) =>
    !editable &&
    `
    pointer-events: none;
    color: grey;
  `}
`;

const SubTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-weight: 200;
  text-align: left;
  width: 100%;
`;
const RoleImageContainer = styled.div`
  height: 84px;
  min-height: 84px;
`;
const RoasterWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 400px;
  justify-content: space-between;
  align-items: center;
`;
interface RoasterContainerProps {
  roasterData: {
    name: string;
    playerList: RoasterPlayer[];
  };
  editable?: boolean;
  onRoasterPlayerChange: (index: number, position: string) => void;
  index: number;
  onClick?: () => void;
  showMinus?: boolean;
  forcingNotEditable?: boolean;
}

export const RoasterContainer: React.FC<RoasterContainerProps> = ({
  roasterData,
  onRoasterPlayerChange,
  index,
  onClick,
  showMinus = true,
  forcingNotEditable = false,
}) => {
  const positions = ['top', 'jungle', 'mid', 'adc', 'support'];
  const {
    nowRoasterPosition,
    setNowRoasterPosition,
    roasterDataList,
    setRoasterDataList,
    roasterChange,
    removeRoaster,
    isRoasterLoading,
    changeOnlyName,
  } = useRoaster();
  const myRoaster = roasterDataList[index];
  const editable = !myRoaster.isbase && !forcingNotEditable;

  const handlePositionClick = (position: string) => {
    if (editable) {
      onRoasterPlayerChange(index, position);
    }
  };

  const handleRoasterNameChange = (roasterIndex: number, name: string) => {
    const newRoasterDataList = [...roasterDataList];
    newRoasterDataList[roasterIndex].name = name;
    if (editable) {
      onRoasterPlayerChange(roasterIndex, '');
    }
    setRoasterDataList([...newRoasterDataList]);
  };

  const handleRoasterNameIndex = (roasterIndex: number) => {
    if (editable) {
      onRoasterPlayerChange(roasterIndex, '');
    }
  };

  return (
    <Roaster onClick={!editable ? onClick : undefined}>
      {isRoasterLoading[index] ? (
        <Spinner />
      ) : (
        <>
          <RoasterHeader>
            <SubTitle>Roaster Name</SubTitle>
            <RoasterInput
              type="text"
              value={roasterData.name}
              onClick={() => handleRoasterNameIndex(index)}
              onChange={(e) => handleRoasterNameChange(index, e.target.value)}
              editable={editable}
              disabled={!editable}
              onBlur={() => {
                changeOnlyName(roasterDataList[index].playerList[0]);
              }}
            />
          </RoasterHeader>
          <RoasterWrapper>
            {roasterData.playerList.map((player, posindex) => {
              const position = positions[posindex];
              if (player && player.name) {
                const data = {
                  name: player.name,
                  image: player.image,
                  position: position,
                };

                return (
                  <ImageWithName
                    key={position}
                    radius={60}
                    image={data.image}
                    name={data.name}
                    onClick={() => handlePositionClick(position)}
                    highlighted={
                      nowRoasterPosition?.roasterIndex === index &&
                      nowRoasterPosition?.position === position
                    }
                    isClickOff={!editable}
                  />
                );
              }
              return (
                <RoleImageContainer>
                  <PlayerRole
                    key={position}
                    radius={60}
                    imageSrc={`/${position.toLowerCase()}.png`}
                    alt={position}
                    onClick={() => handlePositionClick(position)}
                    highlighted={
                      nowRoasterPosition?.roasterIndex === index &&
                      nowRoasterPosition?.position === position
                    }
                    isClickOff={!editable}
                  />
                </RoleImageContainer>
              );
            })}
          </RoasterWrapper>
          {showMinus &&
            (editable ? (
              <IconWrapper>
                <Icon
                  icon={faCircleMinus}
                  onClick={() => removeRoaster(index)}
                />
              </IconWrapper>
            ) : (
              <IconWrapper>
                <Icon
                  icon={faCircleMinus}
                  onClick={() => {}}
                  style={{
                    pointerEvents: 'none',
                    cursor: 'default',
                    opacity: 0.2,
                  }}
                />
              </IconWrapper>
            ))}
        </>
      )}
    </Roaster>
  );
};
