import React, { useState } from "react";
import styled from "styled-components";
import { Input } from "./Input";

interface EmailInputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.9rem;
`;

const EmailInput: React.FC<EmailInputProps> = ({ onChange, value }) => {
  const [isEmailValid, setIsEmailValid] = useState(true);

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    setIsEmailValid(validateEmail(email));
    onChange(event);
  };

  return (
    <>
      <Input id="email" type="email" onChange={handleChange} value={value} />
      {!isEmailValid && <ErrorMessage>유효한 이메일이 아닙니다!</ErrorMessage>}
    </>
  );
};

export default EmailInput;
