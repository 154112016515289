import React, {
  useCallback,
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useMemo,
} from 'react';
import { PlayerData, RoasterPlayer } from '../../pages/userdata/roaster/type';
import * as userDataApis from '../../apis/userDataApis';

// Define the types for the context data
interface RiotAccount {
  riot_id: string;
  region: string;
}

interface PlayerContextType {
  index: number | null;
  updatePlayer: ({ updatedPlayer, index }: updatePlayerProps) => void;
  region: string;
  setRegion: (region: string) => void;
  riot_id: string;
  setRiotId: (riotId: string) => void;
  playerProfileImage: string;
  setPlayerProfileImage: (image: string) => void;
  playerName: string;
  setPlayerName: (name: string) => void;
  playerTeam: string;
  setPlayerTeam: (team: string) => void;
  riotAccount: RiotAccount[];
  setRiotAccount: (account: RiotAccount[]) => void;
  playerPosition: string;
  setPlayerPosition: (position: string) => void;
  playerDebut: string;
  setPlayerDebut: (debut: string) => void;
  setParams: (params: { position: string; keyword: string }) => void;
  playerList: RoasterPlayer[];
  setPlayerList: (playerList: RoasterPlayer[]) => void;
  fetchPutPlayer: (player: RoasterPlayer) => void;
  playerLeague: string;
  setPlayerLeague: (league: string) => void;
  noti: {
    status: string;
    message: string;
    isVisible: boolean;
    isAnimating: boolean;
  };
  setNoti: (noti: {
    status: string;
    message: string;
    isVisible: boolean;
    isAnimating: boolean;
  }) => void;
  isLoading: boolean;
  handleSavePlayer: (player: RoasterPlayer) => void;
  changeSyncronized: (updatedPlayer: RoasterPlayer) => void;
  fetchPlayerList: () => Promise<RoasterPlayer[]>;
  filteredPlayers: RoasterPlayer[];
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  handleAddPlayer: () => void;
  setSearchTermInside: (searchTerm: string) => void;
  setPrioritizedPosition: (position: string) => void;
}

// Create the context
const PlayerContext = createContext<PlayerContextType | undefined>(undefined);

interface Props {
  children: ReactNode;
}

interface updatePlayerProps {
  updatedPlayer: RoasterPlayer;
  index: number;
}

// Create the provider component
export const PlayerProvider: React.FC<Props> = ({ children }) => {
  const [index, setIndex] = useState<number | null>(null);
  const [playerList, setPlayerList] = useState<RoasterPlayer[]>([]);
  const [region, setRegion] = useState<string>('');
  const [riot_id, setRiotId] = useState<string>('');
  const [playerProfileImage, setPlayerProfileImage] =
    useState<string>('/pProfile.png');
  const [playerName, setPlayerName] = useState<string>('');
  const [playerTeam, setPlayerTeam] = useState<string>('');
  const [riotAccount, setRiotAccount] = useState<RiotAccount[]>([]);
  const [playerPosition, setPlayerPosition] = useState<string>('');
  const [playerDebut, setPlayerDebut] = useState<string>('');
  const [params, setParams] = useState<{ position: string; keyword: string }>({
    position: '',
    keyword: '',
  });
  const [playerLeague, setPlayerLeague] = useState<string>('');
  const [noti, setNoti] = useState<{
    status: string;
    message: string;
    isVisible: boolean;
    isAnimating: boolean;
  }>({
    status: '',
    message: '',
    isVisible: false,
    isAnimating: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchPlayerList = useCallback(async () => {
    setIsLoading(true);
    const result = await userDataApis
      .getPlayer(params)
      .then((response) => {
        if (response.code === 200) {
          const newPlayerList = response.players.map(
            (player) =>
              ({
                ...player,
                is_db: true,
                is_syncronized: true,
              } || [])
          );

          setPlayerList([...newPlayerList]);
          setIsLoading(false);
          setNoti({
            status: 'complete',
            message: '플레이어 목록을 불러왔습니다.',
            isVisible: true,
            isAnimating: true,
          });
          return newPlayerList;
        } else {
          setNoti({
            status: 'error',
            message: '플레이어 목록을 불러오는데 실패했습니다.',
            isVisible: true,
            isAnimating: true,
          });
          return [];
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message || '네트워크 연결 오류';
        setIsLoading(false);
        setNoti({
          status: 'error',
          message: errorMessage,
          isVisible: true,
          isAnimating: true,
        });
        return [];
      });
    return result;
  }, [params]);

  useEffect(() => {
    fetchPlayerList();
  }, [fetchPlayerList]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (noti.isVisible) {
      timeout = setTimeout(() => {
        setNoti({ ...noti, isVisible: false });
      }, 800);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [noti]);

  const updatePlayer = useCallback(
    ({ updatedPlayer, index }: updatePlayerProps) => {
      setIndex(index);
      setPlayerName(updatedPlayer.name || '');
      setPlayerTeam(updatedPlayer.team || '');
      setPlayerProfileImage(updatedPlayer.image || '/pProfile.png');
      setRiotAccount(updatedPlayer.riot_id || []);
      setPlayerPosition(updatedPlayer.position || '');
      setPlayerDebut(updatedPlayer.debut || '');
      setPlayerLeague(updatedPlayer.league || '');
    },
    []
  );

  const changeSyncronized = (updatedPlayer: RoasterPlayer) => {
    updatedPlayer.is_syncronized = false;
    updatedPlayer.is_db = updatedPlayer.id === 0 ? false : true;
  };

  const [teamFilter, setTeamFilter] = useState('');
  const [positionFilter, setPositionFilter] = useState('');
  const [leagueFilter, setLeagueFilter] = useState('');
  const [sortField, setSortField] = useState('name'); // 기본 정렬 필드
  const [sortOrder, setSortOrder] = useState('asc'); // 기본 정렬 순서
  const [prioritizedPosition, setPrioritizedPosition] = useState('top'); // 우선 순위에 있는 포지션

  const setSearchTermInside = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setTeamFilter(searchTerm);
    setPositionFilter(searchTerm);
    setLeagueFilter(searchTerm);
  };

  const filteredPlayers = useMemo(() => {
    let filteredPlayers = playerList;

    // nodb 배열 생성 (is_db가 false인 선수들만 포함)
    const nodb = filteredPlayers.filter((player) => player.is_db === false);

    let namefilteredPlayers = playerList;
    let teamfilteredPlayers = playerList;
    let positionfilteredPlayers = playerList;
    let leaguefilteredPlayers = playerList;

    // 필터링
    if (searchTerm.trim() !== '') {
      namefilteredPlayers = filteredPlayers.filter((player) =>
        player.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (teamFilter) {
      teamfilteredPlayers = filteredPlayers.filter((player) =>
        player.team!.toLowerCase().includes(teamFilter.toLowerCase())
      );
    }

    if (positionFilter) {
      positionfilteredPlayers = filteredPlayers.filter((player) =>
        player.position!.toLowerCase().includes(positionFilter.toLowerCase())
      );
    }

    if (leagueFilter) {
      leaguefilteredPlayers = filteredPlayers.filter((player) =>
        player.league!.toLowerCase().includes(leagueFilter.toLowerCase())
      );
    }

    // 중복 제거 및 정렬
    filteredPlayers = Array.from(
      new Set([
        ...namefilteredPlayers,
        ...teamfilteredPlayers,
        ...positionfilteredPlayers,
        ...leaguefilteredPlayers,
      ])
    )
      .filter((player) => !nodb.includes(player)) // nodb에 포함되지 않은 선수들만 필터링
      .sort((a, b) => {
        // 이름이 없는 항목은 뒤로 보내기
        if (!a.name) return 1;
        if (!b.name) return -1;

        // 특정 포지션 우선 정렬
        if (
          a.position === prioritizedPosition &&
          b.position !== prioritizedPosition
        ) {
          return -1;
        }
        if (
          a.position !== prioritizedPosition &&
          b.position === prioritizedPosition
        ) {
          return 1;
        }

        // 이름 기준 정렬
        if (a.name < b.name) {
          return sortOrder === 'asc' ? -1 : 1;
        }
        if (a.name > b.name) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        return 0;
      });

    // 중복을 제거한 후, nodb 배열을 filteredPlayers의 뒤에 추가
    return [...filteredPlayers, ...nodb];
  }, [
    playerList,
    searchTerm,
    teamFilter,
    positionFilter,
    leagueFilter,
    sortOrder,
    prioritizedPosition,
  ]);

  const handleAddPlayer = () => {
    if (isLoading) return;
    const nowPlayerList = playerList;
    const newPlayer = {
      id: 0,
      name: '',
      team: '',
      position: '',
      league: '',
      debut: '',
      riot_id: [],
      is_db: false,
      is_syncronized: false,
      isbase: false,
    } as RoasterPlayer;
    setPlayerList([...nowPlayerList, newPlayer]);
    setIndex(nowPlayerList.length);
    setPlayerName('');
    setPlayerTeam('');
    setPlayerPosition('');
    setPlayerDebut('');
    setPlayerLeague('');
    setRiotAccount([]);
  };

  // TODO: put 혹은 update에 빈칸일 때 에러 메시지 출력

  const fetchPutPlayer = useCallback(
    async (player: RoasterPlayer) => {
      const requestBody = {
        name: playerName,
        team: playerTeam,
        debut: playerDebut,
        riot_id: riotAccount,
        league: playerLeague,
        position: playerPosition,
      };

      await userDataApis
        .putPlayer(requestBody)
        .then((response) => {
          if (response.code === 200) {
            setNoti({
              status: 'complete',
              message: '플레이어 정보를 수정했습니다.',
              isVisible: true,
              isAnimating: true,
            });
          }
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.message || '네트워크 연결 오류';
          setNoti({
            status: 'error',
            message: errorMessage,
            isVisible: true,
            isAnimating: true,
          });
          return error;
        });
    },

    [
      playerName,
      playerTeam,
      playerDebut,
      riotAccount,
      playerLeague,
      playerPosition,
    ]
  );

  const fetchEditPlayer = useCallback(
    async (player: RoasterPlayer) => {
      if (player.id === 0) {
        return;
      }
      const requestBody = {
        id: player.id,
        name: playerName,
        team: playerTeam,
        debut: playerDebut,
        riot_id: riotAccount,
        league: playerLeague,
        position: playerPosition,
      };

      await userDataApis
        .editPlayer(requestBody)
        .then((response) => {
          if (response.code === 200) {
            setNoti({
              status: 'complete',
              message: '플레이어 정보를 수정했습니다.',
              isVisible: true,
              isAnimating: true,
            });
          }
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.message || '네트워크 연결 오류';
          setNoti({
            status: 'error',
            message: errorMessage,
            isVisible: true,
            isAnimating: true,
          });
        });
    },

    [
      playerName,
      playerTeam,
      playerDebut,
      riotAccount,
      playerLeague,
      playerPosition,
    ]
  );

  const handleSavePlayer = useCallback(
    async (player: RoasterPlayer) => {
      if (playerName === '') {
        setNoti({
          status: 'error',
          message: '이름을 입력해주세요.',
          isVisible: true,
          isAnimating: true,
        });
        return;
      }
      setIsLoading(true);
      try {
        if (
          playerName.trim() === '' ||
          playerTeam.trim() === '' ||
          playerDebut.trim() === '' ||
          playerPosition.trim() === '' ||
          playerLeague.trim() === ''
        ) {
          setNoti({
            status: 'error',
            message: '빈칸을 채워주세요.',
            isVisible: true,
            isAnimating: true,
          });
          setIsLoading(false);
          return;
        }
        if (player?.is_db === true && player?.is_syncronized === true) {
          setIsLoading(false);
        } else if (
          player?.is_db === true &&
          player?.is_syncronized === false &&
          player?.isbase === true
        ) {
          await fetchPutPlayer(player);
          await fetchPlayerList();
        } else if (
          player?.is_db === true &&
          player?.is_syncronized === false &&
          player?.isbase === false
        ) {
          await fetchEditPlayer(player);
          await fetchPlayerList();
        } else if (player?.is_db === false) {
          await fetchPutPlayer(player);
          await fetchPlayerList();
        } else {
        }
      } catch (error) {
        setNoti({
          status: 'error',
          message: '플레이어 정보를 수정하는데 실패했습니다.',
          isVisible: true,
          isAnimating: true,
        });
        setIsLoading(false);
      }
    },

    [
      fetchPutPlayer,
      fetchEditPlayer,
      fetchPlayerList,
      playerName,
      playerTeam,
      playerDebut,
      playerPosition,
      playerLeague,
    ]
  );

  return (
    <PlayerContext.Provider
      value={{
        index,
        updatePlayer,
        region,
        setRegion,
        riot_id,
        setRiotId,
        playerProfileImage,
        setPlayerProfileImage,
        playerName,
        setPlayerName,
        playerTeam,
        setPlayerTeam,
        riotAccount,
        setRiotAccount,
        playerDebut,
        setPlayerDebut,
        playerPosition,
        setPlayerPosition,
        setParams,
        playerList,
        setPlayerList,
        fetchPutPlayer,
        playerLeague,
        setPlayerLeague,
        noti,
        setNoti,
        isLoading,
        handleSavePlayer,
        changeSyncronized,
        fetchPlayerList,
        filteredPlayers,
        searchTerm,
        setSearchTerm,
        handleAddPlayer,
        setSearchTermInside,
        setPrioritizedPosition,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};

// Create a custom hook to use the PlayerContext
export const usePlayer = () => {
  const context = useContext(PlayerContext);
  if (!context) {
    throw new Error('usePlayer must be used within a PlayerProvider');
  }
  return context;
};
