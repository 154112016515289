import apiClient from './apiClient';

export const getPatch = async () => {
  try {
    const response = await apiClient.get('/riot/getPatch');

    return response.data;
  } catch (error) {
    throw error;
  }
};
