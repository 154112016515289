import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import WidgetBox from '../component/stage2/WidgetContainer';
import { observer } from 'mobx-react';
import HelpContext from '../../../../assets/helpContext.json';
import TeamPlayerSection from './components/Players';
import LeftIcon from '../../../../assets/leftSelect.svg';
import RightIcon from '../../../../assets/rightSelect.svg';

type HelpContextType = {
  section1: { title: string; content: string }[];
  section3: Record<string, string>;
};

const HelpContextTyped: HelpContextType = HelpContext;

const HelpBoard = styled.div`
  width: 320px;
  height: 214px;
  background: #ffffff;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  padding: 20px;
  box-sizing: border-box;

  transition: transform 0.5s ease; // Smooth slide
`;

const Section = styled.div`
  flex: 0 0 320px; // Section width matches HelpBoard width
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  box-sizing: border-box;

  padding: 5px;
`;

const Container = styled.div`
  height: 100%;
  padding: 10px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div`
  text-align: center;
  font-family: 'Pretendard';
  font-size: 14px;
  font-weight: 700;
  white-space: pre-wrap;

  color: #000000;
`;

const ChamjoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 3px;

  bottom: 10px;

  z-index: 10;
`;

const Chamjo = styled.div<{ $isActivated: boolean }>`
  width: 6px;
  height: 6px;

  background-color: ${(props) =>
    props.$isActivated ? '#7e40ff' : '#7e40ff30'}; // 배경색 변경
`;

const TrafficSign = ({ team }: { team: string | undefined }) => {
  return (
    <div
      style={{
        width: '64px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          width: '26px',
          height: '26px',
          borderRadius: '50%',
          backgroundColor: team === 'blue' ? '#2c31ab' : '#2c31ab10',
        }}
      />
      <div
        style={{
          width: '26px',
          height: '26px',
          borderRadius: '50%',
          backgroundColor: team === 'red' ? '#b20808' : '#b2080810',
        }}
      />
    </div>
  );
};

const LeftButton = styled.img`
  cursor: pointer;
`;
const RightButton = styled.img`
  cursor: pointer;
`;

const StatusIcon = ({
  sectionNumber,
  setSectionNumber,
}: {
  sectionNumber: number;
  setSectionNumber: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return (
    <>
      <ChamjoContainer>
        <LeftButton
          src={LeftIcon}
          onClick={() =>
            setSectionNumber(sectionNumber === 0 ? 2 : sectionNumber - 1)
          }
          width={30}
        />
        {[sectionNumber === 0, sectionNumber === 1, sectionNumber === 2].map(
          (isActivated, index) => (
            <Chamjo key={index} $isActivated={isActivated} />
          )
        )}
        <RightButton
          src={RightIcon}
          onClick={() => setSectionNumber((sectionNumber + 1) % 3)}
          width={30}
        />
      </ChamjoContainer>
    </>
  );
};

const TurnTitle = styled.div`
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  text-align: center;

  color: #000000;
`;

const TurnContext = styled.div`
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  text-align: left;

  color: #000000;
`;

const Section1 = observer(({ vm }: any) => {
  return (
    <Section>
      <div
        style={{
          display: 'flex',
          gap: '20px',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          width: '260px',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <TurnTitle>
            {HelpContextTyped.section1[vm.parentState.turn].title || 'Nooooo!!'}
          </TurnTitle>
          <TrafficSign
            team={
              vm.parentState.turn === 20
                ? undefined
                : [0, 2, 4, 6, 9, 10, 13, 15, 17, 18].includes(
                    vm.parentState.turn
                  )
                ? 'blue'
                : 'red'
            }
          />
        </div>
        <TurnContext>
          {HelpContextTyped.section1[vm.parentState.turn].content || 'Nooooo!!'}
        </TurnContext>
      </div>
    </Section>
  );
});

const Section2 = observer(({ vm }: any) => {
  return (
    <Section>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <TurnTitle>
            {'나의 팀: '}
            <span
              style={{
                color: vm.selectedTeam === 'blue' ? '#2c31ab' : '#b20808',
              }}
            >
              {vm.selectedTeam === 'blue' ? '블루' : '레드'}
            </span>
          </TurnTitle>
        </div>
        <TeamPlayerSection
          team="blue"
          players={vm.blueTeamPlayers}
          editable={false}
          vm={vm}
        />
        <TeamPlayerSection
          team="red"
          players={vm.redTeamPlayers}
          editable={false}
          vm={vm}
        />
      </div>
    </Section>
  );
});

const Section3 = observer(({ vm }: any) => {
  return (
    <Section>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '270px',

          whiteSpace: 'pre-wrap',

          textAlign: 'center',
          fontFamily: 'Pretendard',
          fontWeight: 300,
          fontSize: 14,
        }}
      >
        {HelpContextTyped.section3[vm.helpWidgetState.selectedWidget]}
      </div>
    </Section>
  );
});

const HelpWidget = observer(({ vm }: any) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [currentSection, setCurrentSection] = useState(0);

  const handleWheel = useCallback(
    (e: React.WheelEvent) => {
      if (!scrollRef.current) return;

      e.preventDefault();

      // deltaMode가 0이면 트랙패드, 1이면 휠 마우스
      const isTrackpad = e.deltaMode === 0;
      const delta = isTrackpad ? e.deltaY * 0.5 : e.deltaY;

      if (delta > 20 && currentSection < 2) {
        setCurrentSection((prev) => prev + 1);
      } else if (delta < -20 && currentSection > 0) {
        setCurrentSection((prev) => prev - 1);
      }
    },
    [currentSection]
  );

  useEffect(() => {
    const currentRef = scrollRef.current;

    const handleMouseEnter = (e: Event) => {
      e.preventDefault();
    };

    const handleMouseLeave = () => {};

    if (currentRef) {
      currentRef.addEventListener(
        'wheel',
        handleWheel as unknown as EventListener,
        {
          passive: false,
        }
      );
      currentRef.addEventListener('mouseenter', handleMouseEnter);
      currentRef.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener(
          'wheel',
          handleWheel as unknown as EventListener
        );
        currentRef.removeEventListener('mouseenter', handleMouseEnter);
        currentRef.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [handleWheel]);

  return (
    <WidgetBox vm={vm} type="help">
      <Container>
        <HelpBoard ref={scrollRef}>
          <div
            style={{
              display: 'flex', // Flexbox로 섹션을 가로로 배치
              transform: `translateX(-${currentSection * 320}px)`,
              transition: 'transform 0.5s ease',
              width: '100%',
              height: '100%',
            }}
          >
            <Section1 vm={vm}>
              <Text>Section 1 Content</Text>
            </Section1>
            <Section2 vm={vm}>
              <Text>Section 2 Content</Text>
            </Section2>
            <Section3 vm={vm}>
              <Text>Section 3 Content</Text>
            </Section3>
          </div>
          <StatusIcon
            sectionNumber={currentSection}
            setSectionNumber={setCurrentSection}
          />
        </HelpBoard>
      </Container>
    </WidgetBox>
  );
});

export default HelpWidget;
